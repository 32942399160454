// Authentication
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Users
export const GET_USERS = "GET_USERS";
export const LOADING_USERS = "LOADING_USERS";
export const SELECT_USER = "SELECT_USER";
export const DELETE_USER = "DELETE_USER";
export const ADD_USER = "ADD_USER";

// General
export const SET_ACTIVE_SIDEBAR_NAV = "SET_ACTIVE_SIDEBAR_NAV";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// Tasks
export const GET_MY_TASKS = "GET_MY_TASKS";
export const GET_UNACCEPTED_TASKS = "GET_UNACCEPTED_TASKS";
export const GET_SUBTASKS = "GET_SUBTASKS";
export const LOADING_TASKS = "LOADING_TASKS";
export const SELECT_TASK = "SELECT_TASK";
export const SELECT_MY_TASK = "SELECT_MY_TASK";
export const ACCEPT_TASK = "ACCEPT_TASK";
export const ADD_TASK = "ADD_TASK";
export const ASSIGN_TO_TASK = "ASSIGN_TO_TASK";
export const UNASSIGN_TO_TASK = "UNASSIGN_TO_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";

// Projects
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_UNASSIGNED_PM = "GET_UNASSIGNED_PM";
export const GET_UNASSIGNED_PIC = "GET_UNASSIGNED_PIC";
export const SELECT_PROJECT = "SELECT_PROJECT";
export const SELECT_PROJECT_TASK = "SELECT_PROJECT_TASK";
export const LOADING_PROJECTS = "LOADING_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const ASSIGN_TO_PROJECT = "ASSIGN_TO_PROJECT";
export const UNASSIGN_TO_PROJECT = "UNASSIGN_TO_PROJECT";
export const COMPUTE_PROJECT_PERCENTAGE = "COMPUTE_PROJECT_PERCENTAGE";

// Companies
export const GET_COMPANIES = "GET_COMPANIES";

// Roles
export const GET_ROLES = "GET_ROLES";

// Attendances
export const GET_ATTENDANCES = "GET_ATTENDANCES";
export const LOADING_ATTENDANCES = "LOADING_ATTENDANCES";
export const LOADING_ATTENDANCE_DETAILS = "LOADING_ATTENDANCE_DETAILS";
export const SELECT_ATTENDANCE = "SELECT_ATTENDANCE";
export const GET_ATTENDANCE_DETAILS = "GET_ATTENDANCE_DETAILS";

// Employees
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const LOADING_EMPLOYEES = "LOADING_EMPLOYEES";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const SELECT_EMPLOYEE = "SELECT_EMPLOYEE";
export const EMPLOYEE_DROPDOWN = "EMPLOYEE_DROPDOWN";
export const TEMP_EMPLOYEES = "TEMP_EMPLOYEES"; //Temporarily store employees purposedly to assign it to component's local state.
export const GET_LEAVE_CREDITS = "GET_LEAVE_CREDITS";
export const EMPLOYEE_UPDATE_SCREEN = "EMPLOYEE_UPDATE_SCREEN"; // to make a call after employee has been saved/updated.

// ==================================================
//                  task categories
// ==================================================
export const GET_TASK_CATEGORIES = "GET_TASK_CATEGORIES";
export const ADD_TASK_CATEGORY = "ADD_TASK_CATEGORY";
export const DELETE_TASK_CATEGORY = "DELETE_TASK_CATEGORY";
export const UPDATE_TASK_CATEGORY = "UPDATE_TASK_CATEGORY";
export const SELECT_TASK_CATEGORY = "SELECT_TASK_CATEGORY";
export const LOADING_TASK_CATEGORIES = "LOADING_TASK_CATEGORIES";

// ==================================================
//                     tools
// ==================================================
export const GET_TOOLS = "GET_TOOLS";
export const GET_TASKS_DROPDOWN = "GET_TASKS_DROPDOWN";
export const GET_TOOL_MOVEMENTS = "GET_TOOL_MOVEMENTS";
export const LOADING_TOOLS = "LOADING_TOOLS";
export const SELECT_TOOL = "SELECT_TOOL";
export const GET_TOOL_FILTERS = "GET_TOOL_FILTERS";
export const GET_UNISSUED_TOOLS = "GET_UNISSUED_TOOLS";
export const ADD_TOOL = "ADD_TOOL";
export const DELETE_TOOL = "DELETE_TOOL";
export const UPDATE_TOOL = "UPDATE_TOOL";
export const ADD_REQUEST_TOOL = "ADD_REQUEST_TOOL";

// Tool Categories
export const GET_TOOL_CATEGORIES = "GET_TOOL_CATEGORIES";
export const ADD_TOOL_CATEGORY = "ADD_TOOL_CATEGORY";
export const DELETE_TOOL_CATEGORY = "DELETE_TOOL_CATEGORY";
export const UPDATE_TOOL_CATEGORY = "UPDATE_TOOL_CATEGORY";
export const SELECT_TOOL_CATEGORY = "SELECT_TOOL_CATEGORY";

// Tool Request
export const GET_TOOL_REQUESTS = "GET_TOOL_REQUESTS";
export const GET_PROJECT_TOOL_REQUESTS = "GET_PROJECT_TOOL_REQUESTS";
export const UPDATE_TOOL_REQUEST = "UPDATE_TOOL_REQUEST";
export const SELECT_TOOL_REQUEST = "SELECT_TOOL_REQUEST";
export const APPROVE_TOOL_REQUEST = "APPROVE_TOOL_REQUEST";
export const DENY_TOOL_REQUEST = "DENY_TOOL_REQUEST";

// Tool Scrapped
export const GET_SCRAPPED_TOOLS = "GET_SCRAPPED_TOOLS";
export const ADD_SCRAP_TOOL = "ADD_SCRAP_TOOL";
export const SELECT_SCRAPPED_TOOL = "SELECT_SCRAPPED_TOOL";
export const UNSCRAP_TOOL = "UNSCRAP_TOOL";

// Tool Return
export const RETURN_TOOL = "RETURN_TOOL";

// Tool Delivery
export const ISSUE_TOOL = "ISSUE_TOOL";
export const GET_TOOL_DELIVERIES = "GET_TOOL_DELIVERIES";
export const SELECT_TOOL_DELIVERY = "SELECT_TOOL_DELIVERY";

// Tool Serviced
export const GET_SERVICED_TOOLS = "GET_SERVICED_TOOLS";
export const SELECT_SERVICED_TOOL = "SELECT_SERVICED_TOOL";
export const ADD_SERVICE_TOOL = "ADD_SERVICE_TOOL";

// ==================================================
//                    equipments
// ==================================================
export const GET_EQUIPMENTS = "GET_EQUIPMENTS";
export const LOADING_EQUIPMENTS = "LOADING_EQUIPMENTS";
export const SELECT_EQUIPMENT = "SELECT_EQUIPMENT";
export const GET_EQUIPMENT_FILTERS = "GET_EQUIPMENT_FILTERS";
export const ADD_EQUIPMENT = "ADD_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";

// Equipment Request
export const GET_EQUIPMENT_REQUESTS = "GET_EQUIPMENT_REQUEST";
export const UPDATE_EQUIPMENT_REQUEST = "UPDATE_EQUIPMENT_REQUEST";
export const SELECT_EQUIPMENT_REQUEST = "SELECT_EQUIPMENT_REQUEST";
export const APPROVE_EQUIPMENT_REQUEST = "APPROVE_EQUIPMENT_REQUEST";
export const DENY_EQUIPMENT_REQUEST = "DENY_EQUIPMENT_REQUEST";

// Equipment Scrapped
export const GET_SCRAPPED_EQUIPMENTS = "GET_SCRAPPED_EQUIPMENTS";

// Equipment Serviced
export const GET_SERVICED_EQUIPMENTS = "GET_SERVICED_EQUIPMENTS";
export const SELECT_SERVICED_EQUIPMENT = "SELECT_SERVICED_EQUIPMENT";

// Equipment Categories
export const GET_EQUIPMENT_CATEGORIES = "GET_EQUIPMENT_CATEGORIES";
export const ADD_EQUIPMENT_CATEGORY = "ADD_EQUIPMENT_CATEGORY";
export const DELETE_EQUIPMENT_CATEGORY = "DELETE_EQUIPMENT_CATEGORY";
export const UPDATE_EQUIPMENT_CATEGORY = "UPDATE_EQUIPMENT_CATEGORY";
export const SELECT_EQUIPMENT_CATEGORY = "SELECT_EQUIPMENT_CATEGORY";

// ==================================================
//                    materials
// ==================================================
export const GET_MATERIALS = "GET_MATERIALS";
export const LOADING_MATERIALS = "LOADING_MATERIALS";
export const SELECT_MATERIAL = "SELECT_MATERIAL";
export const ADD_MATERIAL = "ADD_MATERIAL";
export const UPDATE_MATERIAL = "UPDATE_MATERIAL";
export const DELETE_MATERIAL = "DELETE_MATERIAL";
export const GET_PURCHASED_MATERIALS_TOTAL = "GET_PURCHASED_MATERIALS_TOTAL";
export const LOADING_PURCHASED_MATERIALS_TOTAL =
  "LOADING_PURCHASED_MATERIALS_TOTAL";

export const GET_BULK_PO_MATERIALS = "GET_BULK_PO_MATERIALS";
export const GET_BULK_PO_MATERIALS_DROPDOWN = "GET_BULK_PO_MATERIALS_DROPDOWN";
export const LOADING_BULK_PO_MATERIALS = "LOADING_BULK_PO_MATERIALS";
export const SELECT_BULK_PO_MATERIAL = "SELECT_BULK_PO_MATERIAL";
export const ADD_BULK_PO_MATERIAL = "ADD_BULK_PO_MATERIAL";
export const UPDATE_BULK_PO_MATERIAL = "UPDATE_BULK_PO_MATERIAL";
export const DELETE_BULK_PO_MATERIAL = "DELETE_BULK_PO_MATERIAL";
export const SET_LOADING_BULK_PO_PDF = "SET_LOADING_BULK_PO_PDF";

export const GET_REQUEST_PO_MATERIALS = "GET_REQUEST_PO_MATERIALS";
export const GET_REQUEST_PO_RUNNING_NUMBER = "GET_REQUEST_PO_RUNNING_NUMBER";
export const LOADING_REQUEST_PO_MATERIALS = "LOADING_REQUEST_PO_MATERIALS";
export const SELECT_REQUEST_PO_MATERIAL = "SELECT_REQUEST_PO_MATERIAL";
export const ADD_REQUEST_PO_MATERIAL = "ADD_REQUEST_PO_MATERIAL";
export const UPDATE_REQUEST_PO_MATERIAL = "UPDATE_REQUEST_PO_MATERIAL";
export const UPDATE_REQUEST_PO_MATERIAL_STATUS =
  "UPDATE_REQUEST_PO_MATERIAL_STATUS";
export const DELETE_REQUEST_PO_MATERIAL = "DELETE_REQUEST_PO_MATERIAL";
export const SET_LOADING_REQUEST_PO_PDF = "SET_LOADING_REQUEST_PO_PDF";

export const GET_ISSUE_MATERIALS = "GET_ISSUE_MATERIALS";
export const GET_ISSUE_PO_RUNNING_NUMBER = "GET_ISSUE_PO_RUNNING_NUMBER";
export const LOADING_ISSUE_MATERIALS = "LOADING_ISSUE_MATERIALS";
export const SELECT_ISSUE_MATERIAL = "SELECT_ISSUE_MATERIAL";
export const ISSUE_MATERIAL = "ISSUE_MATERIAL";
export const DELETE_ISSUE_MATERIAL = "DELETE_ISSUE_MATERIAL";
export const UPDATE_ISSUE_MATERIAL = "UPDATE_ISSUE_MATERIAL";

export const GET_DELIVERED_MATERIALS = "GET_DELIVERED_MATERIALS";
export const GET_PARTIAL_DELIVERED_MATERIALS =
  "GET_PARTIAL_DELIVERED_MATERIALS";
export const GET_FOR_DELIVERY_MATERIALS = "GET_FOR_DELIVERY_MATERIALS";
export const LOADING_DELIVERY_MATERIALS = "LOADING_DELIVERY_MATERIALS";
export const SELECT_DELIVERED_MATERIAL = "SELECT_DELIVERED_MATERIAL";
export const SELECT_PARTIAL_DELIVERED_MATERIAL =
  "SELECT_PARTIALLY_DELIVERED_MATERIAL";
export const SELECT_FOR_DELIVERY_MATERIAL = "SELECT_FOR_DELIVERY_MATERIAL";
export const DELIVER_MATERIAL = "DELIVER_MATERIAL";
export const DELETE_DELIVERY_MATERIAL = "DELETE_DELIVERY_MATERIAL";

export const GET_AVAILABLE_SPECIALISED_MATERIALS =
  "GET_AVAILABLE_SPECIALISED_MATERIALS";
export const GET_AVAILABLE_SPECIALISED_MATERIALS_DROPDOWN =
  "GET_AVAILABLE_SPECIALISED_MATERIALS_DROPDOWN";
export const GET_LIST_MATERIALS = "GET_LIST_MATERIALS";
export const GET_WITHDRAWAL_REQUESTS = "GET_WITHDRAWAL_REQUESTS";
export const ADD_WITHDRAWAL_REQUEST = "ADD_WITHDRAWAL_REQUEST";
export const UPDATE_WITHDRAWAL_REQUEST = "UPDATE_WITHDRAWAL_REQUEST";
export const LOADING_WITHDRAWAL_REQUESTS = "LOADING_WITHDRAWAL_REQUESTS";
export const SELECT_WITHDRAWAL_REQUEST = "SELECT_WITHDRAWAL_REQUEST";
export const DELETE_WITHDRAWAL_REQUEST = "DELETE_WITHDRAWAL_REQUEST";
export const LOADING_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS =
  "LOADING_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS";
export const GET_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS =
  "GET_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS";
export const SET_LOADING_MATERIALS_EXCEL = "SET_LOADING_MATERIALS_EXCEL";

export const GET_STOCKTAKES = "GET_STOCKTAKES";
export const SELECT_STOCKTAKE = "SELECT_STOCKTAKE";
export const LOADING_STOCKTAKES = "LOADING_STOCKTAKES";
export const ADD_STOCKTAKE = "ADD_STOCKTAKE";
export const DELETE_STOCKTAKE = "DELETE_STOCKTAKE";

// Suppliers - Specialised Material
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const SELECT_SUPPLIER = "SELECT_SUPPLIER";

// Suppliers - Common Material
export const GET_COMMON_SUPPLIERS = "GET_COMMON_SUPPLIERS";
export const ADD_COMMON_SUPPLIER = "ADD_COMMON_SUPPLIER";
export const DELETE_COMMON_SUPPLIER = "DELETE_COMMON_SUPPLIER";
export const UPDATE_COMMON_SUPPLIER = "UPDATE_COMMON_SUPPLIER";
export const SELECT_COMMON_SUPPLIER = "SELECT_COMMON_SUPPLIER";

// ==================================================
//                material requests
// ==================================================
export const GET_MATERIAL_REQUESTS = "GET_MATERIAL_REQUESTS";

// Material Categories
export const GET_MATERIAL_CATEGORIES = "GET_MATERIAL_CATEGORIES";
export const ADD_MATERIAL_CATEGORY = "ADD_MATERIAL_CATEGORY";
export const DELETE_MATERIAL_CATEGORY = "DELETE_MATERIAL_CATEGORY";
export const UPDATE_MATERIAL_CATEGORY = "UPDATE_MATERIAL_CATEGORY";
export const SELECT_MATERIAL_CATEGORY = "SELECT_MATERIAL_CATEGORY";

// Common Material Categories
export const GET_COMMON_MATERIAL_CATEGORIES = "GET_COMMON_MATERIAL_CATEGORIES";
export const ADD_COMMON_MATERIAL_CATEGORY = "ADD_COMMON_MATERIAL_CATEGORY";
export const DELETE_COMMON_MATERIAL_CATEGORY =
  "DELETE_COMMON_MATERIAL_CATEGORY";
export const UPDATE_COMMON_MATERIAL_CATEGORY =
  "UPDATE_COMMON_MATERIAL_CATEGORY";
export const SELECT_COMMON_MATERIAL_CATEGORY =
  "SELECT_COMMON_MATERIAL_CATEGORY";

//Common Issue Purchase Orders Materials
export const GET_COMMON_ISSUE_MATERIALS = "GET_COMMON_ISSUE_MATERIALS";
export const LOADING_COMMON_ISSUE_MATERIALS = "LOADING_COMMON_ISSUE_MATERIALS";
export const SELECT_COMMON_ISSUE_MATERIAL = "SELECT_COMMON_ISSUE_MATERIAL";
export const ISSUE_COMMON_MATERIAL = "ISSUE_COMMON_MATERIAL";
export const DELETE_COMMON_ISSUE_MATERIAL = "DELETE_COMMON_ISSUE_MATERIAL";
export const UPDATE_COMMON_ISSUE_MATERIAL = "UPDATE_COMMON_ISSUE_MATERIAL";

//Common Delivery Order Materials
export const GET_COMMON_DELIVERED_MATERIALS = "GET_COMMON_DELIVERED_MATERIALS";
export const GET_COMMON_FOR_DELIVERY_MATERIALS =
  "GET_COMMON_FOR_DELIVERY_MATERIALS";
export const GET_COMMON_PARTIAL_DELIVERED_MATERIALS =
  "GET_COMMON_PARTIAL_DELIVERED_MATERIALS";
export const LOADING_COMMON_DELIVERY_MATERIALS =
  "LOADING_COMMON_DELIVERY_MATERIALS";
export const SELECT_COMMON_DELIVERED_MATERIAL =
  "SELECT_COMMON_DELIVERED_MATERIAL";
export const SELECT_COMMON_PARTIAL_DELIVERED_MATERIAL =
  "SELECT_COMMON_PARTIAL_DELIVERED_MATERIAL";
export const SELECT_COMMON_FOR_DELIVERY_MATERIAL =
  "SELECT_COMMON_FOR_DELIVERY_MATERIAL";
export const DELIVER_COMMON_MATERIAL = "DELIVER_COMMON_MATERIAL";
export const DELETE_COMMON_DELIVERY_MATERIAL =
  "DELETE_COMMON_DELIVERY_MATERIAL";

//Common Material Requests
export const GET_AVAILABLE_COMMON_MATERIALS = "GET_AVAILABLE_COMMON_MATERIALS";
export const GET_LIST_COMMON_MATERIALS = "GET_LIST_COMMON_MATERIALS";
export const GET_AVAILABLE_COMMON_MATERIALS_DROPDOWN =
  "GET_AVAILABLE_COMMON_MATERIALS_DROPDOWN";
export const GET_COMMON_MATERIAL_REQUESTS = "GET_COMMON_MATERIAL_REQUESTS";
export const ADD_COMMON_MATERIAL_REQUEST = "ADD_COMMON_MATERIAL_REQUEST";
export const UPDATE_COMMON_MATERIAL_REQUEST = "UPDATE_COMMON_MATERIAL_REQUEST";
export const LOADING_COMMON_MATERIAL_REQUESTS =
  "LOADING_COMMON_MATERIAL_REQUESTS";
export const SELECT_COMMON_MATERIAL_REQUEST = "SELECT_COMMON_MATERIAL_REQUEST";
export const DELETE_COMMON_MATERIAL_REQUEST = "DELETE_COMMON_MATERIAL_REQUEST";

//Common Material Allocation
export const GET_FOR_ALLOCATE_MATERIALS = "GET_FOR_ALLOCATE_MATERIALS";
export const GET_ALLOCATED_MATERIALS = "GET_ALLOCATED_MATERIALS";
export const LOADING_ALLOCATE_MATERIALS = "LOADING_ALLOCATE_MATERIALS";
export const SELECT_FOR_ALLOCATE_MATERIAL = "SELECT_FOR_ALLOCATE_MATERIAL";
export const SELECT_ALLOCATED_MATERIAL = "SELECT_ALLOCATED_MATERIAL";
export const ALLOCATE_MATERIAL = "ALLOCATE_MATERIAL";

//Common Material Stocktakes
export const GET_COMMON_STOCKTAKES = "GET_COMMON_STOCKTAKES";
export const SELECT_COMMON_STOCKTAKE = "SELECT_COMMON_STOCKTAKE";
export const LOADING_COMMON_STOCKTAKES = "LOADING_COMMON_STOCKTAKES";
export const ADD_COMMON_STOCKTAKE = "ADD_COMMON_STOCKTAKE";
export const DELETE_COMMON_STOCKTAKE = "DELETE_COMMON_STOCKTAKE";

// ==================================================
//                    Warehouses
// ==================================================
export const GET_WAREHOUSES = "GET_WAREHOUSES";
export const ADD_WAREHOUSE = "ADD_WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";
export const SELECT_WAREHOUSE = "SELECT_WAREHOUSE";

// ==================================================
//                  Documentations
// ==================================================
export const GET_DOCUMENTATIONS = "GET_DOCUMENTATIONS";
export const GET_DOCUMENTATION_NAVIGATIONS = "GET_DOCUMENTATION_NAVIGATIONS";
export const SELECT_DOCUMENTATION = "SELECT_DOCUMENTATION";
export const UPDATE_DOCUMENTATION = "UPDATE_DOCUMENTATION";
export const DELETE_DOCUMENTATION = "DELETE_DOCUMENTATION";
export const ADD_DOCUMENTATION = "ADD_DOCUMENTATION";
export const LOADING_DOCUMENTATIONS = "LOADING_DOCUMENTATIONS";
export const LOADING_DOCUMENTATION_FORM = "LOADING_DOCUMENTATION_FORM";
export const APPROVE_REJECT_DOCUMENTATION = "APPROVE_REJECT_DOCUMENTATION";
export const DELETE_DAILY_PROGRESS_REPORT = "DELETE_DAILY_PROGRESS_REPORT";
export const GET_MANPOWER_COUNT = "GET_MANPOWER_COUNT";
export const LOADING_MANPOWER_COUNT = "LOADING_MANPOWER_COUNT";
