export const requestListColumns = [
  {
    name: "Description",
  },
  {
    name: "Quantity",
  },
  {
    name: "Start Date",
  },
  {
    name: "End Date",
  },
];

export const toolRequestListColumns = [
  {
    name: "Status",
  },
  {
    name: "Date Requested",
  },
  {
    name: "Requested By",
  },
  {
    name: "Tools Requested",
  },
  {
    name: "Comments",
  },
];
