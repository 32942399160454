import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Spinner, Label, Pagination } from "../common";
import { exportListMaterials } from "../../actions/materialWithdrawals";
import { getMaterials } from "../../actions/materials";
import MaterialIcon from "../../assets/img/material_icon.png";
import { getMaterialCategories } from "../../actions/materialCategories";
import { specialisedMaterialListColumns } from "./MyTasksTableColumns";
import useDebounce from "../../helpers/useDebounce";
import { useTransformOption } from "../../helpers/useTransformData";
import classNames from "classnames";
import Select from "react-select";

export default function MyTasksSpecialisedMaterialList(props) {
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials.materials);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const pagination = useSelector(
    (state) => state.materials.paginationMaterials
  );
  const categories = useSelector(
    (state) => state.materialCategories.materialCategories
  );
  const categoriesDropdown = useTransformOption([
    { id: null, name: "All" },
    ...categories,
  ]);
  const isLoading = useSelector((state) => state.materials.materialsLoading);
  const isExcelLoading = useSelector(
    (state) => state.materialWithdrawals.isExcelLoading
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState({
    value: null,
    label: "All",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
    setPageNumber(1);
  };

  const handleTypeChange = (option) => {
    setSelectedType(option);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(
      getMaterials(
        pageNumber,
        selectedTask.type === "projects"
          ? selectedTask.id
          : selectedTask.project_id,
        selectedTask.type === "tasks" ? selectedTask.id : null,
        selectedCategory ? selectedCategory.value : null,
        selectedType ? selectedType.value : null,
        debouncedSearchTerm
      )
    );
  }, [
    dispatch,
    pageNumber,
    selectedTask,
    selectedCategory,
    selectedType,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    dispatch(getMaterialCategories());
  }, [dispatch]);

  return (
    <div className="mt-4">
      <div className="flex mb-4">
        <div className="w-1/4">
          <Label name="Search" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPageNumber(1);
            }}
            type="text"
            placeholder="Search Code or Description..."
          />
        </div>
        <div className="w-1/4 m-4">
          <Button
            primary="true"
            text="Export to Excel"
            disabled={isExcelLoading}
            onClick={() => {
              dispatch(
                exportListMaterials(
                  selectedTask.type === "projects"
                    ? selectedTask.id
                    : selectedTask.project_id,
                  selectedTask.type === "tasks" ? selectedTask.id : null,
                  debouncedSearchTerm
                )
              );
            }}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Type" />
          <Select
            className="text-xs"
            options={[
              { value: null, label: "All" },
              { value: "Specialised", label: "Specialised" },
              { value: "Common", label: "Common" },
            ]}
            value={selectedType}
            onChange={handleTypeChange}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Category" />
          <Select
            className="text-xs"
            options={categoriesDropdown}
            value={selectedCategory}
            onChange={handleCategoryChange}
          />
        </div>
      </div>
      <Table
        columns={specialisedMaterialListColumns}
        data={
          <>
            {!isLoading &&
              materials.map((material, index) => (
                <tr
                  key={material.id}
                  className={classNames("hover:bg-gray-200", {
                    "bg-red-200 hover:bg-red-400": material.in_stock <= 0,
                  })}
                >
                  <td className="px-6 py-2 whitespace-nowrap">
                    <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                      {material.photo_full_url ? (
                        <img
                          alt="..."
                          className="w-full align-middle border-none"
                          src={material.photo_full_url}
                        />
                      ) : (
                        <img
                          alt="..."
                          className="w-full align-middle border-none"
                          src={MaterialIcon}
                        />
                      )}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">{material.type}</div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.category
                        ? material.category.name
                        : "Uncategorized"}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.supplier ? (
                        <div className="flex items-center">
                          <span className="w-24 h-24 text-sm text-white inline-flex items-center justify-center">
                            <img
                              alt="..."
                              className="border border-gray-100 object-cover"
                              src={material.supplier.photo_full_url}
                            />
                          </span>
                          <span className="text-xs ml-2">
                            {material.supplier.name}
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">{material.code}</div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.description}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">{material.unit}</div>
                  </td>
                  {/* <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.warehouse ? material.warehouse.name : "-"}
                    </div>
                  </td> */}
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.bulk_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.request_order_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.delivered_order_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.stocktake_adjustments}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.used_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.in_stock}
                    </div>
                  </td>
                </tr>
              ))}
          </>
        }
      />

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </div>
  );
}
