import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyTasks, getUnacceptedTasks } from "../../actions/tasks";
import swal from "sweetalert";
import ReactHtmlParser from "react-html-parser";
import { Fieldset, Button, Spinner, Modal, Label } from "../common";
import { acceptProject } from "../../actions/projects";
import { acceptTask } from "../../actions/tasks";
import MyCompletedTasks from "./MyCompletedTasks";
import MyAssignedProjects from "./MyAssignedProjects";

export default function MyTasksPage(props) {
  const tasksLoading = useSelector((state) => state.tasks.tasksLoading);
  const unacceptedTasks = useSelector((state) => state.tasks.unacceptedTasks);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTask, setModalTask] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyTasks());
    dispatch(getUnacceptedTasks());
  }, [dispatch]);

  return (
    <div className="m-12">
      {modalOpen && (
        <Modal title={`View Task - ${modalTask && modalTask.title}`}>
          {modalTask && (
            <div>
              <Label name="Title" />
              <p className="text-xs text-gray-700 mb-2">{modalTask.title}</p>

              <Label name="Location" />
              <p className="text-xs text-gray-700 mb-2">{modalTask.location}</p>

              <Label name="Description" />
              <div className="text-xs text-gray-700 mb-2">
                {ReactHtmlParser(modalTask.description)}
              </div>

              <Label name="Start Date" />
              <p className="text-xs text-gray-700 mb-2">
                {modalTask.start_date}
              </p>

              <Label name="End Date" />
              <p className="text-xs text-gray-700 mb-2">{modalTask.end_date}</p>

              <Label name="Created by" />
              <p className="text-xs text-gray-700 mb-2">{`${
                modalTask.created_by
                  ? modalTask.created_by.employee.first_name
                  : ""
              } ${
                modalTask.created_by
                  ? modalTask.created_by.employee.last_name
                  : ""
              }`}</p>
              <hr />
              <Button
                text="Accept"
                success="true"
                onClick={() => {
                  swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `This would notify your leader that you have accepted the project/task.`,
                    buttons: true,
                  }).then((willUpdate) => {
                    if (willUpdate) {
                      if (modalTask.type === "projects") {
                        dispatch(acceptProject(modalTask.id));
                      } else if (modalTask.type === "tasks") {
                        dispatch(acceptTask(modalTask.id));
                      }
                      setModalOpen(false);
                      setModalTask(null);
                    }
                  });
                }}
              />
              <Button
                text="Close"
                primary="true"
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </div>
          )}
        </Modal>
      )}
      {unacceptedTasks.length > 0 && (
        <Fieldset>
          <h1 className="font-bold text-gray-600">
            Newly Assigned Projects/Tasks
          </h1>

          <p className="text-xs text-gray-600 mb-4">
            Kindly accept project/task to start.
          </p>
          <table className="table-auto">
            <thead>
              <tr>
                <th className="w-1/4 px-4 py-2 text-xs">Name</th>
                <th className="w-1/12 px-4 py-2 text-xs">Start Date</th>
                <th className="w-1/12 px-4 py-2 text-xs">End Date</th>
                <th className="w-1/12 px-4 py-2 text-xs">Action</th>
                {/* <th className="w-1/12 px-4 py-2 text-xs">Progress (%)</th> */}
              </tr>
            </thead>
            <tbody>
              {!tasksLoading &&
                unacceptedTasks.map((task) => (
                  <tr
                    key={task.id}
                    className="hover:bg-gray-200 cursor-pointer"
                  >
                    <td className="border px-2 py-2 text-xs">{task.title}</td>
                    <td className="border px-2 py-2 text-xs">
                      {task.start_date}
                    </td>
                    <td className="border px-2 py-2 text-xs">
                      {task.end_date}
                    </td>
                    <td className="border px-2 py-2 text-xs">
                      <Button
                        text="View"
                        success="true"
                        onClick={() => {
                          setModalTask(task);
                          setModalOpen(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {tasksLoading && <Spinner />}
        </Fieldset>
      )}
      <Fieldset>
        <MyAssignedProjects data={props} />
      </Fieldset>
      <Fieldset>
        <MyCompletedTasks data={props} />
      </Fieldset>
    </div>
  );
}
