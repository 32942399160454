import * as yup from 'yup';

export const ToolRequestValidationSchema = yup.object().shape({
    toolDescription: yup.object().required("Please select a Tool Description").nullable(true),
    // category: yup.object().required("Please select a category").nullable(true),
    // description: yup.string().required("Please type a description").nullable(true),
    quantity: yup.number().typeError('Quantity must be a number').positive().integer().min(1, 'Minimum is 1').transform((value, originalValue) => originalValue.trim() === "" ? null: value).required("Please input a quantity").nullable(true),
    startDate: yup.date().required("Please select a start date").nullable(true),
    endDate: yup.date().min(
        yup.ref('startDate'),
        "End date can't be before start date"
    ).required("Please select an end date").nullable(true)
});