import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";
import moment from "moment";

import {
  GET_AVAILABLE_SPECIALISED_MATERIALS,
  LOADING_WITHDRAWAL_REQUESTS,
  GET_WITHDRAWAL_REQUESTS,
  SELECT_WITHDRAWAL_REQUEST,
  ADD_WITHDRAWAL_REQUEST,
  UPDATE_WITHDRAWAL_REQUEST,
  GET_LIST_MATERIALS,
  DELETE_WITHDRAWAL_REQUEST,
  LOADING_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS,
  GET_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS,
  SET_LOADING_MATERIALS_EXCEL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// get available specialised materials
export const getAvailableSpecialisedMaterials =
  (page, project_id, search_term) => (dispatch, getState) => {
    dispatch({ type: LOADING_WITHDRAWAL_REQUESTS });

    let endpoint = `/material-withdrawals/available-materials?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (search_term) endpoint += `&search_term=${search_term}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_AVAILABLE_SPECIALISED_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

export const getListMaterials =
  (page, search_term, project_id = null, task_id = null) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_WITHDRAWAL_REQUESTS });

    let endpoint = `/material-withdrawals/list?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;
    if (search_term) endpoint += `&search_term=${search_term}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_LIST_MATERIALS,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

export const exportListMaterials =
  (project_id = null, task_id = null, search_term = null) =>
  (dispatch, getState) => {
    dispatch({ type: SET_LOADING_MATERIALS_EXCEL, payload: true });
    let endpoint = `/material-withdrawals/export?`;

    if (project_id) endpoint += `project_id=${project_id}&`;
    if (task_id) endpoint += `task_id=${task_id}&`;
    if (search_term) endpoint += `search_term=${search_term}&`;

    axios
      .get(BASE_URL + endpoint, {
        ...tokenConfig(getState),
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Specialised Materials - ${moment()
            .format("Y-MM-DD")
            .toString()}.xlsx`
        );
        dispatch({ type: SET_LOADING_MATERIALS_EXCEL, payload: false });
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch({ type: SET_LOADING_MATERIALS_EXCEL, payload: false });
        console.error(err);
      });
  };

// get withdrawal requests
export const getWithdrawalRequests =
  (page, project_id, task_id, status) => (dispatch, getState) => {
    dispatch({ type: LOADING_WITHDRAWAL_REQUESTS });

    let endpoint = `/material-withdrawals?page=${page}`;

    if (status) endpoint += `&status=${status}`;
    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_WITHDRAWAL_REQUESTS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// select withdrawal request
export const selectWithdrawalRequest = (withdrawRequest) => (dispatch) => {
  dispatch({
    type: SELECT_WITHDRAWAL_REQUEST,
    payload: withdrawRequest,
  });
};

// add withdrawal request
export const addWithdrawalRequest = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/material-withdrawals/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Withdrawal Request Added!",
        icon: "success",
      });
      dispatch({ type: ADD_WITHDRAWAL_REQUEST, payload: res.data });
    })
    .catch((err) => console.error(err));
};

// update withdrawal request status
export const updateWithdrawalRequestStatus =
  (payload) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/material-withdrawals/update-status`;

    axios
      .post(endpoint, payload, tokenConfig(getState))
      .then((res) => {
        swal({
          title: `Withdrawal Request ${payload.status}!`,
          icon: "success",
        });
        dispatch({ type: UPDATE_WITHDRAWAL_REQUEST, payload: res.data });
      })
      .catch((err) => console.error(err));
  };

// update withdrawal request
export const updateWithdrawalRequest = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/material-withdrawals/update`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: `Withdrawal Request Updated!`,
        icon: "success",
      });
      dispatch({ type: UPDATE_WITHDRAWAL_REQUEST, payload: res.data });
    })
    .catch((err) => console.error(err));
};

// delete withdrawal request
export const deleteWithdrawalRequest = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/material-withdrawals/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_WITHDRAWAL_REQUEST,
        payload: id,
      });
      swal({
        title: "Withdrawal Request Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// get withdrawn materials for daily reports
export const getWithdrawnMaterialsForDailyReports =
  (task_id) => (dispatch, getState) => {
    dispatch({ type: LOADING_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS });
    axios
      .get(
        BASE_URL + `/material-withdrawals/for-daily-reports?task_id=${task_id}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };
