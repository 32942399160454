import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_REQUEST_PO_MATERIALS,
  LOADING_REQUEST_PO_MATERIALS,
  SELECT_REQUEST_PO_MATERIAL,
  ADD_REQUEST_PO_MATERIAL,
  UPDATE_REQUEST_PO_MATERIAL_STATUS,
  GET_REQUEST_PO_RUNNING_NUMBER,
  DELETE_REQUEST_PO_MATERIAL,
  UPDATE_REQUEST_PO_MATERIAL,
  SET_LOADING_REQUEST_PO_PDF,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET REQUEST PO MATERIALS
export const getRequestPoMaterials =
  (page, project_id, task_id, status = null) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_REQUEST_PO_MATERIALS });

    let endpoint = `/request-po-materials?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;
    if (status) endpoint += `&status=${status}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_REQUEST_PO_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// GET RUNNING NUMBER
export const getRunningNumber = (task_id) => (dispatch, getState) => {
  let endpoint = `/request-po-materials/get-running-number?task_id=${task_id}`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_REQUEST_PO_RUNNING_NUMBER,
        payload: res.data.running_number,
      });
    })
    .catch((err) => console.error(err));
};

// SELECT REQUEST PO MATERIAL
export const selectRequestPoMaterial = (requestPoMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_REQUEST_PO_MATERIAL,
    payload: requestPoMaterial,
  });
};

// ADD REQUEST PO MATERIAL
export const addRequestPoMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/request-po-materials/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Request PO Added!",
        icon: "success",
      });
      dispatch({ type: ADD_REQUEST_PO_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};

// UPDATE REQUEST PO MATERIAL
export const updateRequestPoMaterial =
  (requestPoMaterial) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/request-po-materials/update`;
    axios
      .post(endpoint, requestPoMaterial, tokenConfig(getState))
      .then((res) => {
        swal({
          title: "Request PO Updated",
          icon: "success",
        });
        dispatch({
          type: UPDATE_REQUEST_PO_MATERIAL,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

// UPDATE REQUEST PO MATERIAL STATUS
export const updateRequestPoMaterialStatus =
  (requestPoMaterial) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/request-po-materials/update-status/`;
    axios
      .post(endpoint, requestPoMaterial, tokenConfig(getState))
      .then((res) => {
        swal({
          title:
            "Request PO " + requestPoMaterial.status === "Approved1" ||
            requestPoMaterial.status === "Approved2"
              ? "Approved"
              : requestPoMaterial.status,
          icon: "success",
        });
        dispatch({
          type: UPDATE_REQUEST_PO_MATERIAL_STATUS,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

// DELETE REQUEST PO MATERIAL
export const deleteRequestPoMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/request-po-materials/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_REQUEST_PO_MATERIAL,
        payload: id,
      });
      swal({
        title: "Request PO Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// GENERATE PDF (EXPORT PDF)
export const generatePDF = (id) => (dispatch, getState) => {
  dispatch({ type: SET_LOADING_REQUEST_PO_PDF, payload: true });
  let endpoint = BASE_URL + `/request-po-materials/${id}/generate`;
  let config = tokenConfig(getState);
  config.method = "GET";
  config.responseType = "blob";

  axios(endpoint, config)
    .then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      dispatch({ type: SET_LOADING_REQUEST_PO_PDF, payload: false });
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: SET_LOADING_REQUEST_PO_PDF, payload: false });
    });
};
