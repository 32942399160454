import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button, Attachment, Avatar } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { updateBulkPoMaterial } from "../../actions/bulkPoMaterials";
import { updateMaterial, deleteMaterial } from "../../actions/materials";

import Select from "react-select";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import { getCompanies } from "../../actions/companies";
import { useTransformOption } from "../../helpers/useTransformData";
import "react-datepicker/dist/react-datepicker.css";

export default function MaterialBulkPOEdit(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companies);
  const selectedBulkPO = useSelector((state) => state.bulkPoMaterials.selected);
  const companiesDropdown = useTransformOption(companies);
  const [contractDate, setContractDate] = useState(
    selectedBulkPO.contract_date
      ? new Date(selectedBulkPO.contract_date)
      : new Date()
  );
  const [materials, setMaterials] = useState(
    selectedBulkPO.materials ? selectedBulkPO.materials : []
  );
  const fileInputRef = useRef();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // Material fields
  //   const [selectedCategory, setSelectedCategory] = useState(null);
  //   const [code, setCode] = useState("");
  //   const [description, setDescription] = useState("");
  //   const [unit, setUnit] = useState("");
  //   const [price, setPrice] = useState("");
  //   const [bulk_qty, setBulkQty] = useState("");
  //   const [warehouse, setWarehouse] = useState("");

  //Register fields that can't be captured by react-hook-form.
  useEffect(() => {
    dispatch(getCompanies());
    register("contract_date");
    setValue(
      "contract_date",
      moment(selectedBulkPO.contract_date).format("YYYY-MM-DD").toString()
    );

    if (selectedBulkPO.company) {
      setSelectedCompany({
        value: selectedBulkPO.company.id,
        label: selectedBulkPO.company.name,
      });
      setValue("company_id", selectedBulkPO.company.id);
    }
  }, [register, setValue, selectedBulkPO, dispatch]);

  const handleContractDateChange = (date) => {
    setContractDate(date);
    setValue("contract_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setValue("company_id", option.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onUpdateBulkPO = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update Bulk Purchase Order for this Task.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        if (file) {
          data.contract_attachment = file;
        }
        dispatch(updateBulkPoMaterial(selectedBulkPO.id, data));
        props.onFormClose && props.onFormClose();
        e.target.reset();
      }
    });
  };

  const handleMaterialChange = (index, event) => {
    const values = [...materials];
    values[index][event.target.name] = event.target.value;

    setMaterials(values);
  };

  const updateMaterialEntry = (material) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the material for this Bulk PO as well as its master entry.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        dispatch(updateMaterial(material.id, material));
      }
    });
  };

  const removeMaterialEntry = (index, material) => {
    swal({
      icon: "error",
      title: "Are you sure?",
      text: `This will delete the material for this Bulk PO as well as its master entry.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        dispatch(deleteMaterial(material.id));
        let values = [...materials];
        values.splice(index, 1);
        setMaterials(values);
      }
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onUpdateBulkPO)}>
        <h1 className="font-bold">Update Bulk Purchase Order</h1>
        <div className="grid">
          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Company (required)" />
              <Select
                className="text-xs"
                options={companiesDropdown}
                value={selectedCompany}
                onChange={(option) => {
                  handleCompanyChange(option);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2">
              <Label name="Bulk PO Series No. (Required)" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="series_no"
                type="text"
                key={selectedBulkPO.series_no}
                defaultValue={selectedBulkPO.series_no}
                readOnly
                required
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Supplier" />
              <p className="text-xs">
                {" "}
                {selectedBulkPO.supplier ? (
                  <Avatar
                    name={selectedBulkPO.supplier.name}
                    image={selectedBulkPO.supplier.photo_full_url}
                  />
                ) : (
                  " - "
                )}
              </p>
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Contract Date" />
              <DatePicker
                showYearDropdown
                selected={contractDate}
                dateFormat="yyyy-MM-dd"
                onChange={handleContractDateChange}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                style={{ zIndex: 10 }}
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Contract Number" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="contract_number"
                type="text"
                key={selectedBulkPO.contract_number}
                defaultValue={selectedBulkPO.contract_number}
                required
              />
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Contract Subject" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="contract_subject"
                type="text"
                key={selectedBulkPO.contract_subject}
                defaultValue={selectedBulkPO.contract_subject}
                required
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Contract File" />
              {selectedBulkPO.contract_attachment ? (
                <Attachment
                  path={selectedBulkPO.contract_attachment_full_url}
                />
              ) : (
                <p className="text-gray-600 text-xs">No attachments.</p>
              )}
              <br />
              <p className="text-xs text-gray-600 mb-4">
                note that uploading will overwrite the existing attachment (if
                any).
              </p>
              <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
          </div>
        </div>

        <Button text="Update Purchase Order" success="true" type="submit" />
      </form>

      <div className="grid bg-gray-200 p-4 mt-4">
        <p className="text-sm font-bold text-gray-600">
          Update Materials to Bulk Purchase Order
        </p>
        <table className="table-fixed w-100">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              <th className="text-xs px-4 py-2">Price</th>
              <th className="text-xs px-4 py-2">Bulk Qty</th>
              {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
              <th className="text-xs px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index} className="hover:bg-blue-200">
                <td className="text-xs border px-2 py-2">
                  {material.category ? material.category.name : "Uncategorized"}
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="code"
                    type="text"
                    value={material.code}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="description"
                    type="text"
                    value={material.description}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="unit"
                    type="text"
                    value={material.unit}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <div className="flex">
                    <p className="m-4">$</p>
                    <input
                      className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="off"
                      name="price"
                      type="text"
                      value={material.price}
                      onChange={(event) => handleMaterialChange(index, event)}
                    />
                  </div>
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="bulk_qty"
                    type="text"
                    value={material.pivot.bulk_qty}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                {/* <td className="text-xs border px-2 py-2">
                  {material.warehouse ? material.warehouse.name : "-"}
                </td> */}
                <td>
                  <div className="flex">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        removeMaterialEntry(index, material);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      className="bg-orange-500 hover:bg-orange-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        updateMaterialEntry(material);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Button
        text="Close"
        primary="true"
        onClick={() => {
          props.onFormClose && props.onFormClose();
        }}
      />
    </div>
  );
}
