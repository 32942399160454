import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner, Pagination, Avatar, Label } from "../common";
import {
  getBulkPoMaterials,
  selectBulkPoMaterial,
  deleteBulkPoMaterial,
  generatePDF,
} from "../../actions/bulkPoMaterials";
import { selectTask } from "../../actions/tasks";
import { roles } from "../../helpers/userRoles";
import { getProjects } from "../../actions/projects";
import MaterialBulkPOAdd from "./MaterialBulkPOAdd";
import MaterialBulkPOEdit from "./MaterialBulkPOEdit";
import { useTransformOption } from "../../helpers/useTransformData";
import swal from "sweetalert";
import Select from "react-select";

export default function MaterialBulkPOPage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const bulkPoMaterials = useSelector(
    (state) => state.bulkPoMaterials.bulkPoMaterials
  );
  const projects = useSelector((state) => state.projects.projects);
  const pagination = useSelector((state) => state.bulkPoMaterials.pagination);
  const isLoading = useSelector((state) => state.bulkPoMaterials.isLoading);
  const isPDFLoading = useSelector(
    (state) => state.bulkPoMaterials.isPDFLoading
  );
  const [pageNumber, setPageNumber] = useState(1);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const [selectedProject, setSelectedProject] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const projectsDropdown = useTransformOption(
    [{ id: null, title: "All" }, ...projects],
    "id",
    "title"
  );

  const handleProjectChange = (option) => {
    setSelectedProject(option);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (props.view === "my-tasks") {
      dispatch(
        getBulkPoMaterials(
          pageNumber,
          selectedTask.type === "projects"
            ? selectedTask.id
            : selectedTask.project_id,
          selectedTask.type === "tasks" ? selectedTask.id : null
        )
      );
    } else {
      dispatch(
        getBulkPoMaterials(
          pageNumber,
          selectedProject ? selectedProject.value : null,
          null
        )
      );
    }
  }, [props.view, selectedTask, selectedProject, pageNumber, dispatch]);

  return (
    <div className="mt-4">
      {props.view === "materials" && (
        <div className="mb-4">
          <Label name="Project" />
          <Select
            className="text-xs"
            options={projectsDropdown}
            value={selectedProject}
            onChange={handleProjectChange}
          />
        </div>
      )}
      {props.view === "materials" ? (
        <div className="mb-4">
          <div className="flex justify-end">
            <Button
              text={`+ Create Bulk Purchase Order`}
              primary="true"
              onClick={() => {
                if (props.view === "materials") {
                  dispatch(selectTask(null));
                }
                setAddModal(true);
              }}
              disabled={
                props.view === "my-tasks"
                  ? selectedTask.type === "projects"
                  : false
              }
            />
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <div className="flex justify-end">
            <p className="text-xs text-gray-600">
              Bulk Purchase Orders are created from Materials.
            </p>
          </div>
        </div>
      )}

      {addModal && (
        <Modal>
          <MaterialBulkPOAdd
            onFormSubmit={() => {
              setAddModal(false);
            }}
            onViewClose={() => {
              setAddModal(false);
            }}
            view={props.view}
          />
        </Modal>
      )}

      {editModal && (
        <Modal>
          <MaterialBulkPOEdit
            onFormClose={() => {
              setEditModal(false);
            }}
          />
        </Modal>
      )}

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Series No.</th>
            <th className="text-xs px-4 py-2">Contract Date</th>
            <th className="text-xs px-4 py-2">Contract #</th>
            <th className="text-xs px-4 py-2">Prepared By</th>
            {props.view === "materials" && (
              <>
                <th className="text-xs px-4 py-2">Project</th>
                <th className="text-xs px-4 py-2">Task</th>
              </>
            )}
            <th className="text-xs px-4 py-2">
              Materials
              <br />
              (Code - Desc. (Qty))
            </th>
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {bulkPoMaterials.map((bulkPoMaterial) => (
            <tr
              key={bulkPoMaterial.id}
              className="hover:bg-gray-200"
              onClick={() => {
                dispatch(selectBulkPoMaterial(bulkPoMaterial));
              }}
            >
              <td className="text-xs border px-2 py-2">
                {bulkPoMaterial.series_no}
              </td>
              <td className="text-xs border px-2 py-2">
                {bulkPoMaterial.contract_date}
              </td>
              <td className="text-xs border px-2 py-2">
                {bulkPoMaterial.contract_number}
              </td>
              <td className="text-xs border px-2 py-2">
                {bulkPoMaterial.prepared_by ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        {bulkPoMaterial.prepared_by.avatar && (
                          <img
                            alt="Supplier"
                            className="rounded-full border border-gray-100 object-cover"
                            src={bulkPoMaterial.prepared_by.avatar.image}
                          />
                        )}
                      </span>
                      <span className="text-xs ml-2">
                        {bulkPoMaterial.prepared_by.first_name}{" "}
                        {bulkPoMaterial.prepared_by.last_name}
                        <br />
                        {bulkPoMaterial.prepared_by.contact_number}
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </td>
              {props.view === "materials" && (
                <>
                  <td className="text-xs border px-2 py-2">
                    {bulkPoMaterial.project
                      ? `${bulkPoMaterial.project.title}`
                      : "-"}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {bulkPoMaterial.task ? `${bulkPoMaterial.task.title}` : "-"}
                  </td>
                </>
              )}
              <td className="text-xs border px-2 py-2">
                {bulkPoMaterial.materials
                  ? bulkPoMaterial.materials.map((material) => (
                      <ul key={material.id} className="list-disc ml-4">
                        <li>
                          {material.code} - {material.description} (
                          {material.pivot.bulk_qty} {material.unit})
                        </li>
                      </ul>
                    ))
                  : "-"}
              </td>
              <td className="text-xs border px-2 py-2">
                {/* <Button
                  text={`View`}
                  primary="true"
                  onClick={() => {
                    dispatch(selectBulkPoMaterial(bulkPoMaterial));
                    //setViewModal(true);
                  }}
                /> */}
                {props.view === "materials" && (
                  <>
                    <Button
                      text={`Edit`}
                      warning="true"
                      onClick={() => {
                        dispatch(selectBulkPoMaterial(bulkPoMaterial));
                        setEditModal(true);
                      }}
                    />
                    <Button
                      text={`Generate PDF`}
                      primary="true"
                      isLoading={isPDFLoading}
                      onClick={() => {
                        dispatch(generatePDF(bulkPoMaterial.id));
                      }}
                    />
                    {user.employee.role_id === roles.su && (
                      <Button
                        text={`Delete`}
                        danger="true"
                        onClick={() => {
                          swal({
                            icon: "error",
                            title: "Are you sure?",
                            text: `This will delete the Bulk Purchase Order.`,
                            buttons: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              dispatch(deleteBulkPoMaterial(bulkPoMaterial.id));
                            }
                          });
                        }}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </div>
  );
}
