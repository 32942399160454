import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { addBulkPoMaterial } from "../../actions/bulkPoMaterials";
import { getMaterialCategories } from "../../actions/materialCategories";
import { getCompanies } from "../../actions/companies";
import { getSuppliers } from "../../actions/suppliers";
import { getProjects } from "../../actions/projects";
import { getTasksDropdown } from "../../actions/tasks";
import { searchMaterials } from "../../actions/materials";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";
import { useTransformOption } from "../../helpers/useTransformData";

export default function MaterialBulkPOAdd(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companies);
  const projects = useSelector((state) => state.projects.projects);
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const companiesDropdown = useTransformOption(companies);
  const projectsDropdown = useTransformOption(projects, "id", "title");
  const suppliersDropdown = useTransformOption(suppliers);
  const tasksDropdown = useSelector((state) => state.tasks.tasksDropdown);
  const [contractDate, setContractDate] = useState(new Date());
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const [selectedTaskDropdown, setSelectedTaskDropdown] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [materials, setMaterials] = useState([]);

  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  // Material fields
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [bulk_qty, setBulkQty] = useState("");

  //Register fields that can't be captured by react-hook-form.
  useEffect(() => {
    dispatch(getCompanies());
    register("contract_date");
    register("delivery_date");
    register("company_id");
    // dispatch(getMaterialCategories());
    // dispatch(getWarehouses());
    dispatch(getProjects());
    dispatch(getSuppliers());
  }, [register, dispatch]);

  const handleContractDateChange = (date) => {
    setContractDate(date);
    setValue("contract_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setValue("company_id", option.value);
  };

  const addMaterial = () => {
    if (!selectedMaterial) {
      alert("Please select a material first.");
      return;
    }
    if (!bulk_qty || bulk_qty <= 0) {
      alert("Bulk Quantity Input is required");
      return;
    }
    let material = {
      id: selectedMaterial.material.id,
      type: selectedMaterial.material.type,
      category: selectedMaterial.material.category?.name,
      code: selectedMaterial.material.code,
      description: selectedMaterial.material.description,
      unit: selectedMaterial.material.unit,
      material_id: selectedMaterial.material.id,
      bulk_qty: bulk_qty,
      price: selectedMaterial.material.price,
      warehouse: selectedMaterial.material.warehouse?.name,
    };

    setMaterials([...materials, material]);
    setSelectedMaterial(null);
    setSelectedSupplier(null);
  };

  const loadMaterialOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchMaterials(inputValue, null, selectedSupplier?.value)).then(
        (res) => {
          resolve(res);
        }
      );
    });

    let options = [];

    for (let i in search) {
      options.push({
        value: search[i].id,
        label: `${search[i].code} - ${search[i].description}`,
        material: search[i],
      });
    }
    callback(options);
  };

  const removeMaterial = (material) => {
    setMaterials(materials.filter((ma) => ma.id !== material.id));
  };

  const handleSelectedMaterialChange = (option) => {
    setSelectedMaterial(option);
  };

  const handleProjectChange = (option) => {
    setSelectedProject(option);
    if (option) {
      //Load tasks
      dispatch(getTasksDropdown(option.value));
    }
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  const onSubmit = async (data, e) => {
    if (!data.series_no) {
      alert("Bulk PO Series No. is required.");
      return;
    }

    if (!selectedProject) {
      // if (!selectedProject || !selectedTaskDropdown) {
      //   alert("Please assign it to project and task.");
      //   return;
      // }
      alert("Please assign it to project and task.");
      return;
    }

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add Bulk Purchase Order for this Task.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        data.contract_attachment = file;

        //add materials
        data.materials = materials;
        data.prepared_by = user.employee.id;

        if (selectedTaskDropdown && selectedProject) {
          data.task_id = selectedTaskDropdown.value;
          data.project_id = selectedProject.value;
        } else if (selectedProject) {
          data.task_id = null;
          data.project_id = selectedProject.value;
        }

        if (selectedCompany) {
          data.company_id = selectedCompany.value;
        }
        // else {
        //   data.task_id = selectedTask.id;
        //   data.project_id = selectedTask.project_id;
        // }

        dispatch(addBulkPoMaterial(data));
        props.onFormSubmit && props.onFormSubmit();
        e.target.reset();
      }
    });
  };

  //Set it before initializing component to prevent null errors.
  setValue("contract_date", moment(new Date()).format("YYYY-MM-DD").toString());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold mb-4">Create Bulk Purchase Order</h1>
      {selectedTask && (
        <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>
      )}

      <div className="flex mt-4">
        <div className="w-1/2">
          <Label name="Company (required)" />
          <Select
            className="text-xs"
            options={companiesDropdown}
            value={selectedCompany}
            onChange={(option) => {
              handleCompanyChange(option);
            }}
          />
        </div>
      </div>

      <div className="flex mt-4">
        <div className="w-1/2">
          <Label name="Bulk PO Series No. (Required)" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            name="series_no"
            type="text"
            required
          />
        </div>
      </div>

      <div className="grid">
        {!selectedTask && (
          <div className="flex mt-4 mb-4">
            <div className="w-1/2">
              <Label name="Project" />
              <Select
                className="text-xs"
                options={projectsDropdown}
                value={selectedProject}
                onChange={handleProjectChange}
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Task (optional)" />
              <Select
                className="text-xs"
                options={tasksDropdown}
                value={selectedTaskDropdown}
                onChange={setSelectedTaskDropdown}
              />
            </div>
          </div>
        )}

        <div className="flex mt-4">
          <div className="w-1/2">
            <Label name="Contract Date" />
            <DatePicker
              showYearDropdown
              selected={contractDate}
              dateFormat="yyyy-MM-dd"
              onChange={handleContractDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Contract Number (optional)" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="contract_number"
              type="text"
            />
          </div>
        </div>

        <div className="flex mt-4">
          <div className="w-1/2">
            <Label name="Contract Subject  (optional)" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="contract_subject"
              type="text"
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Contract File  (optional)" />
            <input
              className="text-xs"
              ref={fileInputRef}
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className="grid bg-gray-200 p-4 mt-4">
        <p className="text-sm font-bold text-gray-600 mb-4">
          + Add Materials to Bulk Purchase Order
        </p>
        <div className="flex mb-4">
          <div className="w-1/4">
            <Label name="Supplier" />
            <Select
              className="text-xs"
              options={suppliersDropdown}
              value={selectedSupplier}
              onChange={handleSupplierChange}
            />
          </div>

          <div className="w-2/4 px-4">
            <Label name="Search Code/Description" />
            <AsyncSelect
              loadOptions={loadMaterialOptions}
              onChange={handleSelectedMaterialChange}
              value={selectedMaterial}
              className="text-xs shadow-sm"
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Bulk Qty." />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="order_qty"
              type="number"
              value={bulk_qty}
              onChange={(e) => setBulkQty(e.target.value)}
              required
            />
          </div>

          <div className="w-1/4 m-4 px-4">
            <Button
              text={`+ Add Material`}
              primary="true"
              onClick={() => {
                addMaterial();
              }}
            />
          </div>
        </div>

        <table className="table-fixed w-100">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Type</th>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
              <th className="text-xs px-4 py-2">Bulk Qty</th>
              <th className="text-xs px-4 py-2">
                <i className="far fa-trash text-gray-500 text-base"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index} className="hover:bg-blue-200">
                <td className="text-xs border px-2 py-2">{material.type}</td>
                <td className="text-xs border px-2 py-2">
                  {material.category}
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                {/* <td className="text-xs border px-2 py-2">
                  {material.warehouse}
                </td> */}
                <td className="text-xs border px-2 py-2">
                  {material.bulk_qty}
                </td>
                <td>
                  <Button
                    text="-"
                    danger="true"
                    onClick={() => {
                      removeMaterial(material);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button text="Submit Bulk Order" success="true" type="submit" />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </form>
  );
}
