import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Label,
  Button,
  Typography,
  EmptyPlaceholder,
  Pagination,
  Spinner,
} from "../common";
import moment from "moment";
import swal from "sweetalert";
import {
  getAvailableSpecialisedMaterials,
  addWithdrawalRequest,
} from "../../actions/materialWithdrawals";
import DatePicker from "react-datepicker";
import useDebounce from "helpers/useDebounce";

export default function MaterialWithdrawalAdd(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const availableMaterials = useSelector(
    (state) => state.materialWithdrawals.availableMaterials
  );
  const availableMaterialsPagination = useSelector(
    (state) => state.materialWithdrawals.availableMaterialsPagination
  );
  const isLoading = useSelector((state) => state.materialWithdrawals.isLoading);
  const [date, setDate] = useState(new Date());
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [chosenMaterial, setChosenMaterial] = useState(null);
  const [chosenMaterialQty, setChosenMaterialQty] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const addSelectedMaterial = () => {
    let selected = {
      ...chosenMaterial,
      withdraw_qty: chosenMaterialQty,
    };

    setSelectedMaterials([...selectedMaterials, selected]);
    setChosenMaterialQty(0);
    setChosenMaterial(null);
  };

  const removeSelectedMaterial = (material) => {
    setSelectedMaterials(
      selectedMaterials.filter((ma) => ma.id !== material.id)
    );
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleSelectMaterial = (material) => {
    setChosenMaterial(material);
  };

  const onSubmit = () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add Withdrawal Request for this Task.`,
      buttons: true,
    }).then((willAdd) => {
      if (willAdd) {
        let withdrawMaterial = {
          task_id: selectedTask.id,
          project_id: selectedTask.project_id,
          status: "Pending",
          requested_by: user.employee_id,
          date: moment(date).format("YYYY-MM-DD").toString(),
          materials: selectedMaterials,
        };

        dispatch(addWithdrawalRequest(withdrawMaterial));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  useEffect(() => {
    dispatch(
      getAvailableSpecialisedMaterials(
        pageNumber,
        selectedTask.project_id,
        debouncedSearchTerm
      )
    );
  }, [dispatch, pageNumber, debouncedSearchTerm, selectedTask]);

  return (
    <form>
      <Typography type="h1">Create Withdrawal Request</Typography>
      {selectedTask && (
        <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>
      )}

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Requested by" />
          <p className="text-xs">
            {user.employee.first_name} {user.employee.last_name}
          </p>
        </div>
        <div className="w-1/2 px-4">
          <Label name="Request Date" />
          <DatePicker
            showYearDropdown
            selected={date}
            dateFormat="yyyy-MM-dd"
            onChange={handleDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            style={{ zIndex: 10 }}
          />
        </div>
      </div>
      <Typography type="h1">Selected Materials</Typography>
      <Typography type="muted">
        Please choose materials from the "Choose Materials to Withdraw" table.
      </Typography>
      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">Withdraw Qty</th>
            <th className="text-xs px-4 py-2">Remove?</th>
          </tr>
        </thead>
        <tbody>
          {selectedMaterials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200 ">
              <td className="text-xs border px-2 py-2">
                {material.category ? material.category.name : "Uncategorized"}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">
                {material.withdraw_qty}
              </td>
              <td className="text-xs border px-2 py-2">
                <Button
                  text="-"
                  danger="true"
                  onClick={() => {
                    removeSelectedMaterial(material);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mb-4">
        <Button
          success="true"
          text="Save"
          disabled={selectedMaterials < 1}
          onClick={() => {
            onSubmit();
          }}
        />
      </div>

      <Typography type="h1">Choose Materials to Withdraw</Typography>
      <h3 className="font-bold">
        Materials available under "{selectedTask.title}"
      </h3>
      <Typography type="muted">
        Search available materials, click on the row of the desired material,
        put in quantity and click "+ add item" button.
      </Typography>

      <div className="flex mb-4">
        <div className="w-1/4">
          <Label name="Category" />
          <p className="text-xs font-bold">
            {chosenMaterial && chosenMaterial.category?.name}
          </p>
        </div>
        <div className="w-1/4 px-4">
          <Label name="Material" />
          <p className="text-xs font-bold">
            {chosenMaterial &&
              `${chosenMaterial.code} - ${chosenMaterial.description}`}
          </p>
        </div>
        <div className="w-1/4 px-4">
          <Label name="Available Stock" />
          <p className="text-xs font-bold">
            {chosenMaterial && `${chosenMaterial.in_stock}`}
          </p>
        </div>
        <div className="w-1/4 px-4">
          <Label name="Qty to Withdraw" />
          <input
            type="number"
            autoComplete="off"
            disabled={!chosenMaterial}
            value={chosenMaterialQty}
            onChange={(e) => {
              setChosenMaterialQty(e.target.value);
            }}
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
          />
        </div>
      </div>
      <div className="mb-4">
        <Button
          primary="true"
          text="+ add item"
          disabled={!chosenMaterial}
          onClick={() => {
            addSelectedMaterial();
          }}
        />
      </div>

      <Label name="Search for Available Materials" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        type="text"
      />

      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">
              Available
              <br />
              Stock
            </th>
          </tr>
        </thead>
        <tbody>
          {availableMaterials.map((material, index) => (
            <tr
              key={index}
              className="hover:bg-gray-200 cursor-pointer"
              onClick={() => {
                handleSelectMaterial(material);
              }}
            >
              <td className="text-xs border px-2 py-2">
                {material.category ? material.category.name : "Uncategorized"}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">{material.in_stock}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {availableMaterials < 1 && !isLoading && (
        <EmptyPlaceholder text="No Available Materials Found" />
      )}

      {isLoading && <Spinner />}

      {availableMaterialsPagination && !isLoading && (
        <Pagination
          from={availableMaterialsPagination.from}
          to={availableMaterialsPagination.to}
          total={availableMaterialsPagination.total}
          lastPage={availableMaterialsPagination.last_page}
          currentPage={availableMaterialsPagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}

      <Button
        primary="true"
        text="Close"
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </form>
  );
}
