import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Label } from "../common";
import ImageUtils from "../../helpers/ImageUtils";
import ImageUploader from "react-images-upload";
import {
  addMaterial,
  updateMaterial,
  selectMaterial,
  deleteMaterial,
} from "../../actions/materials";
import { getSuppliers } from "../../actions/suppliers";
import { getWarehouses } from "../../actions/warehouses";
import { getMaterialCategories } from "../../actions/materialCategories";
import swal from "sweetalert";
import Select from "react-select";
import { useTransformOption } from "../../helpers/useTransformData";

export default function MaterialForm(props) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedMaterial = useSelector(
    (state) => state.materials.selectedMaterial
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = useSelector(
    (state) => state.materialCategories.materialCategories
  );
  // const warehouses = useSelector((state) => state.warehouses.warehouses);
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const categoriesDropdown = useTransformOption(categories);
  const suppliersDropdown = useTransformOption([{ id: null, name: "None" }, ...suppliers]);
  // const warehousesDropdown = useTransformOption(warehouses);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [photo, setPhoto] = useState([]);
  const [selectedType, setSelectedType] = useState({
    value: "Specialised",
    label: "Specialised",
  });

  const imageUploadRef = useRef(null);
  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const onSubmit = (data, e) => {
    // e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will ${
        selectedMaterial ? "update" : "add to"
      } the list of materials.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let tempImg = null;

        for (let i = 0; i < photo.length; i++) {
          tempImg = await ImageUtils.resizeImage(photo[i]);
        }

        if (tempImg) {
          data.photo = tempImg;
          data.photo_full_url = tempImg;
        }

        data.type = selectedType.value;
        data.supplier_id = selectedSupplier?.value;
        // data.warehouse_id = selectedWarehouse?.value;
        data.material_category_id = selectedCategory?.value;

        if (selectedMaterial) {
          dispatch(updateMaterial(selectedMaterial.id, data));
        } else {
          dispatch(addMaterial(data));
        }
        props.onViewClose && props.onViewClose();
        props.onSubmit && props.onSubmit({ ...selectedMaterial, ...data });
      }
    });
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  // const handleWarehouseChange = (option) => {
  //   setSelectedWarehouse(option);
  // };

  const handleTypeChange = (option) => {
    setSelectedType(option);
  };

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
    setValue("material_category_id", option.value);
  };

  useEffect(() => {
    setSelectedCategory(
      selectedMaterial && selectedMaterial.category
        ? {
            value: selectedMaterial.category.id,
            label: selectedMaterial.category.name,
          }
        : null
    );
    setSelectedSupplier(
      selectedMaterial && selectedMaterial.supplier
        ? {
            value: selectedMaterial.supplier.id,
            label: selectedMaterial.supplier.name,
          }
        : null
    );
    // setSelectedWarehouse(
    //   selectedMaterial && selectedMaterial.warehouse
    //     ? {
    //         value: selectedMaterial.warehouse.id,
    //         label: selectedMaterial.warehouse.name,
    //       }
    //     : null
    // );
    setSelectedType(
      selectedMaterial && selectedMaterial.type
        ? {
            value: selectedMaterial.type,
            label: selectedMaterial.type,
          }
        : null
    );
  }, [selectedMaterial]);

  useEffect(() => {
    dispatch(getMaterialCategories());
    dispatch(getSuppliers());
    // dispatch(getWarehouses());
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedMaterial
          ? `Material - ${selectedMaterial.code} ${selectedMaterial.description}`
          : `Add Material`}
      </h1>

      <div className="flex mb-4">
        <div className="w-1/3">
          <Label name="Type" />
          <Select
            className="text-xs"
            options={[
              { value: "Specialised", label: "Specialised" },
              { value: "Common", label: "Common" },
            ]}
            value={selectedType}
            onChange={handleTypeChange}
          />
        </div>

        <div className="w-1/3 px-4">
          <Label name="Category" />
          <Select
            options={categoriesDropdown}
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="text-xs"
          />
        </div>

        <div className="w-1/3 px-4">
          <Label name="Supplier" />
          <Select
            className="text-xs"
            options={suppliersDropdown}
            value={selectedSupplier}
            onChange={handleSupplierChange}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Code" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={selectedMaterial ? selectedMaterial.code : "code"}
            defaultValue={selectedMaterial ? selectedMaterial.code : ""}
            name="code"
            type="text"
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Description" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={
              selectedMaterial ? selectedMaterial.description : "description"
            }
            defaultValue={selectedMaterial ? selectedMaterial.description : ""}
            name="description"
            type="text"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Unit" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={selectedMaterial ? selectedMaterial.unit : "unit"}
            defaultValue={selectedMaterial ? selectedMaterial.unit : ""}
            name="unit"
            type="text"
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Price ($)" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={selectedMaterial ? selectedMaterial.price : "price"}
            defaultValue={selectedMaterial ? selectedMaterial.price : ""}
            name="price"
            type="number"
            step="any"
            min="0"
          />
        </div>

        {/* <div className="w-1/3 px-4">
          <Label name="Warehouse" />
          <Select
            className="text-xs"
            options={warehousesDropdown}
            value={selectedWarehouse}
            onChange={handleWarehouseChange}
          />
        </div> */}
      </div>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Photo" />
          {selectedMaterial && (
            <p className="text-gray-600 text-xs">
              Current photo saved. Upload only if you want to change the photo.
            </p>
          )}
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            singleImage={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>

      <br />

      <div className="justify-center flex mt-4 px-4">
        {selectedMaterial ? (
          <>
            <Button text="Update" success="true" type="submit" />
            <Button
              onClick={() => {
                swal({
                  icon: "warning",
                  title: "Are you sure?",
                  text: `This will remove the materials and its issuances.`,
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    dispatch(deleteMaterial(selectedMaterial.id));
                    selectMaterial(null);
                    reset();
                  }
                });
              }}
              danger
              text="Delete"
            />
          </>
        ) : (
          <Button
            text="Save"
            success="true"
            type="submit"
            // onClick={() => {
            //   onSubmit();
            // }}
          />
        )}
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </form>
  );
}
