import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner, Pagination, Label } from "../common";
import swal from "sweetalert";
import {
  getIssuePoMaterials,
  deleteIssuePoMaterial,
  generatePDF,
  selectIssuePoMaterial,
} from "../../actions/issuePoMaterials";
import { getProjects } from "../../actions/projects";
import { roles } from "../../helpers/userRoles";
// import MaterialRequestOrderView from "./MaterialRequestOrderView";
import { useTransformOption } from "../../helpers/useTransformData";
import MaterialIssuePOEdit from "./MaterialIssuePOEdit";
import Select from "react-select";

export default function MaterialIssuePOPage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const issuePoMaterials = useSelector(
    (state) => state.issuePoMaterials.issuePoMaterials
  );
  const pagination = useSelector((state) => state.issuePoMaterials.pagination);
  const projects = useSelector((state) => state.projects.projects);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const [selectedProject, setSelectedProject] = useState(null);
  const isLoading = useSelector((state) => state.issuePoMaterials.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [viewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const projectsDropdown = useTransformOption(
    [{ id: null, title: "All" }, ...projects],
    "id",
    "title"
  );

  const handleProjectChange = (option) => {
    setSelectedProject(option);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (props.view === "my-tasks") {
      dispatch(
        getIssuePoMaterials(
          pageNumber,
          selectedTask.type === "projects"
            ? selectedTask.id
            : selectedTask.project_id,
          selectedTask.type === "tasks" ? selectedTask.id : null
        )
      );
    } else {
      dispatch(
        getIssuePoMaterials(
          pageNumber,
          selectedProject ? selectedProject.value : null,
          null
        )
      );
    }
  }, [props.view, selectedTask, selectedProject, pageNumber, dispatch]);

  return (
    <div className="mt-4">
      {/* <div className="float-right">
        <Button text={`+ Request Order from Bulk PO`} primary="true" />
        <Button text={`+ Ad-hoc Request Order`} primary="true" />
      </div> */}

      {viewModal && (
        <Modal>
          {/* <MaterialRequestOrderView
            onFormClose={() => {
              setViewModal(false);
            }}
          /> */}
        </Modal>
      )}

      {editModal && (
        <Modal>
          <MaterialIssuePOEdit
            onFormClose={() => {
              setEditModal(false);
            }}
          />
        </Modal>
      )}

      {props.view === "materials" && (
        <div className="mb-4">
          <Label name="Project" />
          <Select
            className="text-xs"
            options={projectsDropdown}
            value={selectedProject}
            onChange={handleProjectChange}
          />
        </div>
      )}

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Date</th>
            <th className="text-xs px-4 py-2">Company</th>
            <th className="text-xs px-4 py-2">Issue PO S/N</th>
            <th className="text-xs px-4 py-2">Request PO S/N</th>
            <th className="text-xs px-4 py-2">Supplier</th>
            <th className="text-xs px-4 py-2">Person to Contact</th>
            <th className="text-xs px-4 py-2">Warehouse</th>
            <th className="text-xs px-4 py-2">Issued By</th>
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {issuePoMaterials.map((issuePoMaterial) => (
            <tr key={issuePoMaterial.id} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.date}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.company ? issuePoMaterial.company.name : "-"}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.ref_no}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.request_purchase_order
                  ? issuePoMaterial.request_purchase_order.series_no
                  : "-"}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.supplier ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        <img
                          alt="Supplier"
                          className="rounded-full border border-gray-100 object-cover"
                          src={issuePoMaterial.supplier.photo_full_url}
                        />
                      </span>
                      <span className="text-xs ml-2">
                        {issuePoMaterial.supplier.name}
                        <br />
                        {issuePoMaterial.supplier.contact_person}
                        <br />
                        {issuePoMaterial.supplier.contact_number}
                        <br />
                        {issuePoMaterial.supplier.address}
                        <br />
                      </span>
                    </div>
                  </>
                ) : (
                  " - "
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.ship_to ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        {issuePoMaterial.ship_to.avatar && (
                          <img
                            alt="Supplier"
                            className="rounded-full border border-gray-100 object-cover"
                            src={issuePoMaterial.ship_to.avatar.image}
                          />
                        )}
                      </span>
                      <span className="text-xs ml-2">
                        {issuePoMaterial.ship_to.first_name}{" "}
                        {issuePoMaterial.ship_to.last_name}
                        <br />
                        {issuePoMaterial.ship_to.contact_number}
                        <br />
                        {issuePoMaterial.ship_to_address}
                        <br />
                      </span>
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.warehouse
                  ? issuePoMaterial.warehouse.name
                  : "-"}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.issued_by ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        {issuePoMaterial.issued_by.avatar && (
                          <img
                            alt="Supplier"
                            className="rounded-full border border-gray-100 object-cover"
                            src={issuePoMaterial.issued_by.avatar.image}
                          />
                        )}
                      </span>
                      <span className="text-xs ml-2">
                        {issuePoMaterial.issued_by.first_name}{" "}
                        {issuePoMaterial.issued_by.last_name}
                        <br />
                        {issuePoMaterial.issued_by.contact_number}
                      </span>
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                <div className="flex">
                  <div className="flex-initial">
                    <Button
                      text={`Generate PDF`}
                      primary="true"
                      onClick={() => {
                        dispatch(generatePDF(issuePoMaterial.id));
                      }}
                    />
                  </div>

                  {!issuePoMaterial.has_deliveries &&
                    user.employee.role_id === roles.su && (
                      <>
                        <div className="flex-initial">
                          <Button
                            text={`Edit`}
                            warning="true"
                            onClick={() => {
                              dispatch(selectIssuePoMaterial(issuePoMaterial));
                              setEditModal(true);
                            }}
                          />
                        </div>
                        <div className="flex-initial">
                          <Button
                            text={`Delete`}
                            danger="true"
                            onClick={() => {
                              swal({
                                icon: "error",
                                title: "Are you sure?",
                                text: `This will delete this Issue Purchase Order.`,
                                buttons: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    deleteIssuePoMaterial(issuePoMaterial.id)
                                  );
                                }
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </div>
  );
}
