import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { deliverMaterial } from "../../actions/deliverMaterials";
import { searchEmployees } from "../../actions/employees";
import { getSuppliers } from "../../actions/suppliers";
import { useTransformOption } from "../../helpers/useTransformData";
import Select from "react-select";
import ImageUtils from "../../helpers/ImageUtils";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";

export default function MaterialDeliveryPartialForm(props) {
  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const suppliersDropdown = useTransformOption(suppliers);
  const selectedPartialDelivered = useSelector(
    (state) => state.deliverMaterials.selectedPartialDelivered
  );
  const [checkedState, setCheckedState] = useState(
    new Array(selectedPartialDelivered.materials.length).fill(false)
  );
  const [inputFields, setInputFields] = useState(
    new Array(selectedPartialDelivered.materials.length).fill({
      stock_qty: 0,
      delivery_price: 0,
    })
  );
  const [deliveredBy, setDeliveredBy] = useState("");
  const [receivedBy, setReceivedBy] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [deliveredDate, setDeliveredDate] = useState(new Date());
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDeliveredDateChange = (date) => {
    setDeliveredDate(date);
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const handleReceivedByChange = (option) => {
    setReceivedBy(option);
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  const loadRecipientOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "")).then((res) => {
        resolve(res.data);
      });
    });

    let recipientOptions = [];

    for (let i in search) {
      recipientOptions.push({
        value: search[i].id,
        label: `${search[i].first_name} ${search[i].last_name}`,
      });
    }

    callback(recipientOptions);
  };

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const handleAllChecked = (event) => {
    const updatedCheckedState = checkedState.map(() => event.target.checked);

    setCheckedState(updatedCheckedState);
  };

  const handleInputChange = (position, event) => {
    const values = inputFields.map((item, index) => {
      if (index !== position) {
        return item;
      }

      return {
        ...item,
        [event.target.name]: event.target.value,
      };
    });

    setInputFields(values);
  };

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPartialDelivered.materials) {
      let values = [];
      selectedPartialDelivered.materials.forEach((m) => {
        const value = {
          ...m,
          stock_qty: 0,
          delivery_price: m.price,
        };

        values.push(value);
      });

      setInputFields(values);
    }
  }, [selectedPartialDelivered]);

  const onSubmit = () => {
    if (!selectedFile) {
      alert("Attachment is required.");
      return;
    }
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will tag the issued purchase order as delivered.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }

        let selectedMaterials = [];
        selectedPartialDelivered.materials.forEach((material, index) => {
          if (checkedState[index] === true) {
            selectedMaterials.push({
              id: material.id,
              stock_qty: inputFields[index].stock_qty,
              delivery_price: inputFields[index].delivery_price,
            });
          }
        });

        let data = {
          materials: selectedMaterials,
          issue_purchase_order_id: selectedPartialDelivered.id,
          task_id: selectedPartialDelivered.task_id,
          project_id: selectedPartialDelivered.project_id,
          supplier_id: selectedSupplier?.value,
          delivered_by: "N/A",
          received_by: receivedBy.value,
          delivered_date: moment(deliveredDate).format("YYYY-MM-DD").toString(),
        };

        data.attachment = file;

        dispatch(deliverMaterial(data));
        props.onFormClose && props.onFormClose();
        props.onFormSubmit && props.onFormSubmit();
      }
    });
  };

  return (
    <form>
      <h1 className="font-bold">Deliver Issued Order</h1>
      {selectedPartialDelivered && (
        <p className="text-xs text-gray-600 mb-4">
          Issue PO S/N: {selectedPartialDelivered.ref_no}
        </p>
      )}

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Received by" />
          <AsyncSelect
            loadOptions={loadRecipientOptions}
            value={receivedBy}
            onChange={handleReceivedByChange}
            className="text-xs shadow-sm"
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Date delivered" />
          <DatePicker
            showYearDropdown
            selected={deliveredDate}
            dateFormat="yyyy-MM-dd"
            onChange={handleDeliveredDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            style={{ zIndex: 10 }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Attachment" />
          <input
            className="text-xs"
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Place of Delivery" />
          <p className="text-xs">
            {selectedPartialDelivered.warehouse
              ? selectedPartialDelivered.warehouse.name
              : "-"}
          </p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Supplier" />
          <Select
            className="text-xs shadow-sm"
            options={suppliersDropdown}
            value={selectedSupplier}
            onChange={handleSupplierChange}
          />
        </div>
      </div>

      <p className="font-bold mt-4">Materials</p>
      <p className="text-xs text-gray-600">
        please choose materials that was delivered.
      </p>
      <p className="text-xs text-gray-600 mb-4">
        note: delivered quantity would be the actual stock count.
      </p>
      <div className="ml-4">
        <input
          name="select_all"
          type="checkbox"
          onChange={(e) => handleAllChecked(e)}
        />
        <label for="select_all" className="text-xs font-bold ml-4">
          Check/Uncheck All
        </label>
      </div>
      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Select</th>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">Base Price</th>
            <th className="text-xs px-4 py-2">
              Price <br />
              (upon delivery)
            </th>
            {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
            <th className="text-xs px-4 py-2">Ordered Qty</th>
            <th className="text-xs px-4 py-2">
              Partially <br /> Delivered Qty
            </th>
            <th className="text-xs px-4 py-2">
              Delivered Qty <br />
              (Stock Qty)
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedPartialDelivered.materials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td>
                <input
                  type="checkbox"
                  name="material_id[]"
                  checked={checkedState[index]}
                  onChange={() => handleCheckboxChange(index)}
                  disabled={
                    material.partial_delivered_qty >= material.pivot.issue_qty
                  }
                  className="ml-4"
                />
              </td>
              <td className="text-xs border px-2 py-2">
                {material.category ? material.category.name : "Uncategorized"}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">${material.price}</td>
              <td className="text-xs border px-2 py-2">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  id={`delivery_price-${index}`}
                  name="delivery_price"
                  type="text"
                  value={inputFields[index].delivery_price}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </td>
              {/* <td className="text-xs border px-2 py-2">
                {material.warehouse ? material.warehouse.name : ""}
              </td> */}
              <td className="text-xs border px-2 py-2">
                {material.pivot.issue_qty}
              </td>
              <td className="text-xs border px-2 py-2">
                {material.partial_delivered_qty}
              </td>
              <td className="text-xs border px-2 py-2">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  id={`stock_qty-${index}`}
                  name="stock_qty"
                  type="text"
                  value={inputFields[index].stock_qty}
                  disabled={
                    material.partial_delivered_qty >= material.pivot.issue_qty
                  }
                  onChange={(event) => handleInputChange(index, event)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Submit Delivered Orders"
          success="true"
          onClick={() => {
            onSubmit();
          }}
          disabled={checkedState.filter(Boolean).length <= 0}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onFormClose() && props.onFormClose();
          }}
        />
      </div>
    </form>
  );
}
