import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label, Spinner } from "../common";
import { SRLWrapper } from "simple-react-lightbox";
import swal from "sweetalert";
import {
  generatePDF,
  selectDocumentation,
  deleteDocumentation,
} from "../../actions/documentations";

export default function ViewToolboxMeeting(props) {
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const isFormLoading = useSelector(
    (state) => state.documentations.isFormLoading
  );
  const toolboxMeeting = React.createRef();

  return (
    <div>
      {isFormLoading ? (
        <div className="items-center">
          <Spinner />
          <p className="text-center text-sm m-4">
            Please wait while we generate the PDF...
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1" style={{ marginLeft: -10 }}>
          <div>
            <button
              onClick={() => {
                dispatch(generatePDF(selectedDocument, "toolbox"));
              }}
              className="bg-blue-500 hover:bg-blue-700 text-xs text-white m-2 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Generate PDF
            </button>
            <Button
              text="Edit"
              primary="true"
              onClick={() => {
                props.onEditClick && props.onEditClick();
              }}
            />
          </div>
        </div>
      )}
      <hr />
      <div className="shadow-md">
        <div
          className="grid grid-cols-2 gap-4"
          ref={toolboxMeeting}
          style={{
            paddingTop: 30,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
          }}
        >
          <div>
            <Label name="Date" />
            <p className="text-sm mb-4">{selectedDocument.date}</p>
          </div>
          <div>
            <Label name="Safety" />
            <p className="text-sm mb-4">{selectedDocument.safety}</p>
          </div>
          <div>
            <Label name="Supervisor" />
            <p className="text-sm mb-4">{selectedDocument.supervisor}</p>
          </div>
          <div>
            <Label name="Worker" />
            <p className="text-sm mb-4">{selectedDocument.worker}</p>
          </div>
          <div>
            <Label name="Subcon" />
            <p className="text-sm mb-4">{selectedDocument.subcon}</p>
          </div>
          <div>
            <Label name="Supply Labour" />
            <p className="text-sm mb-4">{selectedDocument.supply_labour}</p>
          </div>
          <div>
            <Label name="Total" />
            <p className="text-sm mb-4">{selectedDocument.total}</p>
          </div>
          <div>
            <Label name="Uploaded By" />
            <p className="text-sm mb-4">
              {selectedDocument.created_by.first_name},{" "}
              {selectedDocument.created_by.last_name}
            </p>
          </div>
        </div>
      </div>
      <br />

      <Label name="Photos" />
      <p className="text-gray-600 text-xs mb-4">Click photo to enlarge.</p>

      {selectedDocument && selectedDocument.files ? (
        <SRLWrapper>
          <div className="flex">
            {selectedDocument.files.map((attachment, index) => (
              <img
                alt={`attachment-${index}`}
                className="border-none p-4"
                src={attachment.full_path}
                style={{
                  width: "256px",
                  height: "256px",
                }}
              />
            ))}
          </div>
        </SRLWrapper>
      ) : (
        <p className="text-gray-600 text-xs">No attachments.</p>
      )}
      <div className="float-right">
        <Button
          text="Delete"
          danger="true"
          onClick={() => {
            swal({
              icon: "warning",
              title: "Are you sure?",
              text: `Once deleted, selected elect inspection will be removed.`,
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                dispatch(deleteDocumentation(selectedDocument.id));
                props.onViewClose && props.onViewClose();
                selectDocumentation(null);
              }
            });
          }}
        />
      </div>
      <div className="flex">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
