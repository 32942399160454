import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_ISSUE_MATERIALS,
  GET_ISSUE_PO_RUNNING_NUMBER,
  LOADING_ISSUE_MATERIALS,
  SELECT_ISSUE_MATERIAL,
  ISSUE_MATERIAL,
  DELETE_ISSUE_MATERIAL,
  UPDATE_ISSUE_MATERIAL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET ISSUE PO MATERIALS
export const getIssuePoMaterials =
  (page, project_id, task_id) => (dispatch, getState) => {
    dispatch({ type: LOADING_ISSUE_MATERIALS });

    let endpoint = `/issue-po-materials?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_ISSUE_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// GET RUNNING NUMBER
export const getRunningNumber = (series_no) => (dispatch, getState) => {
  let endpoint = `/issue-po-materials/get-running-number?series_no=${series_no}`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ISSUE_PO_RUNNING_NUMBER,
        payload: res.data.running_number,
      });
    })
    .catch((err) => console.error(err));
};

// SELECT ISSUE PO MATERIAL
export const selectIssuePoMaterial = (requestPoMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_ISSUE_MATERIAL,
    payload: requestPoMaterial,
  });
};

// ADD ISSUE PO MATERIAL
export const addIssuePoMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/issue-po-materials/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Issue PO Added!",
        icon: "success",
      });
      dispatch({ type: ISSUE_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};

export const generatePDF = (id) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/issue-po-materials/${id}/generate`;
  let config = tokenConfig(getState);
  config.method = "GET";
  config.responseType = "blob";

  axios(endpoint, config)
    .then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch((err) => console.error(err));
};

export const updateIssuePoMaterial =
  (issuePoMaterial) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/issue-po-materials/update`;
    axios
      .post(endpoint, issuePoMaterial, tokenConfig(getState))
      .then((res) => {
        swal({
          title: "Issue PO Updated",
          icon: "success",
        });
        dispatch({
          type: UPDATE_ISSUE_MATERIAL,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

export const deleteIssuePoMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/issue-po-materials/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_ISSUE_MATERIAL,
        payload: id,
      });
      swal({
        title: "Issue PO Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
