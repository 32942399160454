import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeScreen } from "../../actions/employees";
import { Label } from "components/common";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

const Form = (props) => {
  const dispatch = useDispatch();
  const {
    active,
    companiesDropdown,
    rolesDropdown,
    Controller,
    control,
    register,
    setValue,
    selectedEmployee,
  } = props;
  const isUpdateEmployeeScreen = useSelector(
    (state) => state.employees.isUpdateEmployeeScreen
  );
  const [enableEditing, setEnableEditing] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [probationEndDate, setProbationEndDate] = useState(null);
  const [wpApplicationDate, setWpApplicationDate] = useState(null);
  const [wpExpiryDate, setWpExpiryDate] = useState(null);
  const [airportPassExpiryDate, setAirportPassExpiryDate] = useState(null);
  const [passportExpiryDate, setPassportExpiryDate] = useState(null);

  useEffect(() => {
    register("start_date");
    register("end_date");
    register("probation_end_date");
    register("wp_application_date");
    register("wp_expiry_date");
    register("airport_pass_expiry_date");
    register("passport_expiry_date");

    selectedEmployee && selectedEmployee.start_date
      ? setValue(
          "start_date",
          moment(new Date(selectedEmployee.start_date))
            .format("YYYY-MM-DD")
            .toString()
        )
      : setValue("start_date", null);

    setStartDate(
      selectedEmployee && selectedEmployee.start_date
        ? new Date(selectedEmployee.start_date)
        : null
    );

    selectedEmployee && selectedEmployee.end_date
      ? setValue(
          "end_date",
          moment(new Date(selectedEmployee.end_date))
            .format("YYYY-MM-DD")
            .toString()
        )
      : setValue("end_date", null);

    setEndDate(
      selectedEmployee && selectedEmployee.end_date
        ? new Date(selectedEmployee.end_date)
        : null
    );

    selectedEmployee && selectedEmployee.probation_end_date
      ? setValue(
          "probation_end_date",
          moment(new Date(selectedEmployee.probation_end_date))
            .format("Y-MM-DD")
            .toString()
        )
      : setValue("probation_end_date", null);

    setProbationEndDate(
      selectedEmployee && selectedEmployee.probation_end_date
        ? new Date(selectedEmployee.probation_end_date)
        : null
    );

    selectedEmployee && selectedEmployee.wp_application_date
      ? setValue(
          "wp_application_date",
          moment(new Date(selectedEmployee.wp_application_date))
            .format("Y-MM-DD")
            .toString()
        )
      : setValue("wp_application_date", null);

    setWpApplicationDate(
      selectedEmployee && selectedEmployee.wp_application_date
        ? new Date(selectedEmployee.wp_application_date)
        : null
    );

    selectedEmployee && selectedEmployee.wp_expiry_date
      ? setValue(
          "wp_expiry_date",
          moment(new Date(selectedEmployee.wp_expiry_date))
            .format("Y-MM-DD")
            .toString()
        )
      : setValue("wp_expiry_date", null);

    setWpExpiryDate(
      selectedEmployee && selectedEmployee.wp_expiry_date
        ? new Date(selectedEmployee.wp_expiry_date)
        : null
    );

    selectedEmployee && selectedEmployee.airport_pass_expiry_date
      ? setValue(
          "airport_pass_expiry_date",
          moment(new Date(selectedEmployee.airport_pass_expiry_date))
            .format("Y-MM-DD")
            .toString()
        )
      : setValue("airport_pass_expiry_date", null);

    setAirportPassExpiryDate(
      selectedEmployee && selectedEmployee.airport_pass_expiry_date
        ? new Date(selectedEmployee.airport_pass_expiry_date)
        : null
    );

    selectedEmployee && selectedEmployee.passport_expiry_date
      ? setValue(
          "passport_expiry_date",
          moment(new Date(selectedEmployee.passport_expiry_date))
            .format("Y-MM-DD")
            .toString()
        )
      : setValue("passport_expiry_date", null);

    setPassportExpiryDate(
      selectedEmployee && selectedEmployee.passport_expiry_date
        ? new Date(selectedEmployee.passport_expiry_date)
        : null
    );
  }, [register, selectedEmployee, setValue]);

  useEffect(() => {
    if (isUpdateEmployeeScreen === true) {
      setEnableEditing(false);
      dispatch(updateEmployeeScreen());
    }
  }, [dispatch, isUpdateEmployeeScreen]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValue("start_date", moment(date).format("Y-MM-DD").toString());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValue("end_date", moment(date).format("Y-MM-DD").toString());
  };

  const handleProbationEndDateChange = (date) => {
    setProbationEndDate(date);
    setValue("probation_end_date", moment(date).format("Y-MM-DD").toString());
  };

  const handleWpApplicationDateChange = (date) => {
    setWpApplicationDate(date);
    setValue("wp_application_date", moment(date).format("Y-MM-DD").toString());
  };

  const handleWpExpiryDateChange = (date) => {
    setWpExpiryDate(date);
    setValue("wp_expiry_date", moment(date).format("Y-MM-DD").toString());
  };

  const handleAirportPassExpiryDateChange = (date) => {
    setAirportPassExpiryDate(date);
    setValue(
      "airport_pass_expiry_date",
      moment(date).format("Y-MM-DD").toString()
    );
  };

  const handlePassportExpiryDateChange = (date) => {
    setPassportExpiryDate(date);
    setValue("passport_expiry_date", moment(date).format("Y-MM-DD").toString());
  };

  if (active) {
    return (
      <div>
        <div className="w-1/2" style={{ marginLeft: 18, marginTop: 10 }}>
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              name="enable_editing"
              id="toggle"
              checked={enableEditing}
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              onChange={(event) => {
                setEnableEditing(event.currentTarget.checked);
              }}
            />
            <label
              htmlFor="toggle"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label htmlFor="toggle" className="text-xs text-gray-700">
            Enable Editing
          </label>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="Years of Service" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.start_date}
              defaultValue={moment().diff(
                moment(selectedEmployee.start_date, "yyyy-MM-dd"),
                "years"
              )}
              name="yrs_of_service"
              type="text"
              disabled={!enableEditing}
            />
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="Company" />
            <Controller
              control={control}
              name="company_id"
              defaultValue={selectedEmployee.company.id}
              render={({ onChange, onBlue, value, name }) => {
                const currentSelected = companiesDropdown.find(
                  (i) => i.value === value
                );
                return (
                  <Select
                    options={companiesDropdown}
                    value={
                      currentSelected
                        ? currentSelected
                        : { label: "", value: "" }
                    }
                    onChange={(event) => onChange(event.value)}
                    isDisabled={!enableEditing}
                    className="text-xs"
                  />
                );
              }}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="Company Employee Code" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.unique_id}
              defaultValue={selectedEmployee.unique_id}
              name="unique_id"
              type="text"
              disabled={!enableEditing}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="Role" />
            <Controller
              control={control}
              name="role_id"
              defaultValue={selectedEmployee.employee_role.id}
              render={({ onChange, onBlue, value, name }) => {
                const currentSelected = rolesDropdown.find(
                  (i) => i.value === value
                );
                return (
                  <Select
                    name={name}
                    options={rolesDropdown}
                    value={
                      currentSelected
                        ? currentSelected
                        : { label: "", value: "" }
                    }
                    onChange={(event) => onChange(event.value)}
                    isDisabled={!enableEditing}
                    className="text-xs"
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="Start Date" />
            <DatePicker
              showYearDropdown
              selected={startDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handleStartDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="End Date" />
            <DatePicker
              showYearDropdown
              selected={endDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handleEndDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>

          <div className="w-1/3 px-2">
            <Label name="Probation End Date" />
            <DatePicker
              showYearDropdown
              selected={probationEndDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handleProbationEndDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="WP No" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.wp_no}
              defaultValue={selectedEmployee.wp_no}
              name="wp_no"
              type="text"
              disabled={!enableEditing}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="WP Application Date" />
            <DatePicker
              showYearDropdown
              selected={wpApplicationDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handleWpApplicationDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>

          <div className="w-1/3 px-2">
            <Label name="WP Expiry Date" />
            <DatePicker
              showYearDropdown
              selected={wpExpiryDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handleWpExpiryDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="Pass Type" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.pass_type}
              defaultValue={selectedEmployee.pass_type}
              name="pass_type"
              type="text"
              disabled={!enableEditing}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="MYE" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.mye}
              defaultValue={selectedEmployee.mye}
              name="mye"
              type="text"
              disabled={!enableEditing}
            />
          </div>

          <div className="w-1/3 px-2">
            <Label name="NRIC/FIN" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.nric}
              defaultValue={selectedEmployee.nric}
              name="nric"
              type="text"
              disabled={!enableEditing}
            />
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="Passport No." />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.passport_no}
              defaultValue={selectedEmployee.passport_no}
              name="passport_no"
              type="text"
              disabled={!enableEditing}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="Passport Expiry Date" />
            <DatePicker
              showYearDropdown
              selected={passportExpiryDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handlePassportExpiryDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="Airport Pass Expiry Date" />
            <DatePicker
              showYearDropdown
              selected={airportPassExpiryDate}
              ref={register}
              dateFormat="yyyy-MM-dd"
              disabled={!enableEditing}
              onChange={handleAirportPassExpiryDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            />
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export default Form;
