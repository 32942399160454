import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import swal from "sweetalert";
import {
  Fieldset,
  Pagination,
  Spinner,
  Tab,
  Typography,
  Table,
  StyledDialog,
  Modal,
} from "../common";
import {
  getToolRequests,
  selectToolRequest,
  approveToolRequest,
  denyToolRequest,
} from "../../actions/tools";
import { toolListRequestColumns } from "./ToolTableColumns";
import {
  ApproveButton,
  DenyButton,
  IssueButton,
  ViewButton,
} from "../common/icons/HeroIcons";

import { LOADING_TOOLS } from "../../actions/types";
import ToolIssuingForm from "./ToolIssuingForm";
import ToolRequestDetail from "./ToolRequestDetail";

export default function ToolRequestPage(props) {
  const dispatch = useDispatch();
  const toolRequests = useSelector((state) => state.tools.toolRequests);
  const selectedToolRequest = useSelector(
    (state) => state.tools.selectedToolRequest
  );
  const pagination = useSelector((state) => state.tools.paginationToolRequests);
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);
  const [status, setStatus] = useState("Pending");
  const [pageNumber, setPageNumber] = useState(1);
  const [commentValue, setCommentValue] = useState("");
  const [issueModal, setIssueModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  useEffect(() => {
    dispatch(getToolRequests(status, pageNumber));
  }, [dispatch, status, pageNumber]);

  const handleCommentChange = (e) => {
    setCommentValue(e.target.value);
  };

  return (
    <div className="flex">
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">Tools Requests</Typography>
          <Tab>
            <Tab.Item
              name="Pending"
              isActive={status === "Pending"}
              onTabClick={() => {
                setStatus("Pending");
              }}
            />
            <Tab.Item
              name="Approved"
              isActive={status === "Approved"}
              onTabClick={() => {
                setStatus("Approved");
              }}
            />
            <Tab.Item
              name="Denied"
              isActive={status === "Denied"}
              onTabClick={() => {
                setStatus("Denied");
              }}
            />
          </Tab>

          <Table
            columns={toolListRequestColumns}
            data={
              <>
                {!toolsLoading &&
                  toolRequests.map((toolRequest) => (
                    <tr
                      key={toolRequest.id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selectedToolRequest
                              ? selectedToolRequest.id
                              : 0) === toolRequest.id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectToolRequest(toolRequest));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.created_at.split(" ")[0]}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.project && toolRequest.project.title}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.requested_by &&
                            `${toolRequest.requested_by.first_name} ${toolRequest.requested_by.last_name}`}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tools.length}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          <input
                            key={toolRequest.id}
                            type="text"
                            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                            style={{ border: "1px solid #ccc" }}
                            autoComplete="off"
                            onChange={handleCommentChange}
                            value={
                              toolRequest.approved_comments ||
                              toolRequest.denied_comments
                            }
                            disabled={status === "Pending" ? false : true}
                          />
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectToolRequest(toolRequest));
                              setViewModal(true);
                            }}
                          >
                            <ViewButton title={"Detail"} />
                          </div>
                        </div>
                      </td>
                      {status === "Pending" ? (
                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex flex-row justify-between">
                            <div
                              className="flex flex-col text-gray-600 hover:text-gray-900"
                              onClick={() => {
                                swal({
                                  icon: "success",
                                  title: "Are you sure?",
                                  text: `Approving this tool request. Confirm?`,
                                  buttons: true,
                                }).then((willApprove) => {
                                  if (willApprove) {
                                    dispatch({ type: LOADING_TOOLS });
                                    dispatch(
                                      approveToolRequest(
                                        selectedToolRequest.id,
                                        commentValue
                                      )
                                    );
                                  }
                                });
                              }}
                              onMouseEnter={() => {
                                dispatch(selectToolRequest(toolRequest));
                              }}
                            >
                              <ApproveButton title={"Approve"} />
                            </div>
                            <div
                              className="flex flex-col text-gray-600 hover:text-gray-900"
                              onClick={() => {
                                swal({
                                  icon: "warning",
                                  title: "Are you sure?",
                                  text: `Denying this tool request. Confirm?`,
                                  buttons: true,
                                }).then((willDelete) => {
                                  if (willDelete) {
                                    dispatch({ type: LOADING_TOOLS });
                                    dispatch(
                                      denyToolRequest(
                                        selectedToolRequest.id,
                                        commentValue
                                      )
                                    );
                                  }
                                });
                              }}
                              onMouseEnter={() => {
                                dispatch(selectToolRequest(toolRequest));
                              }}
                            >
                              <DenyButton title={"Deny"} />
                            </div>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      {status === "Approved" && (
                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex flex-row justify-between">
                            <div
                              className="flex flex-col text-gray-600 hover:text-gray-900"
                              onClick={() => {
                                dispatch(selectToolRequest(toolRequest));
                                setIssueModal(true);
                              }}
                            >
                              <IssueButton title={"Issue"} />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </>
            }
          />

          {toolsLoading && <Spinner />}

          {pagination && !toolsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>
      </div>

      {issueModal && (
        <Modal onClose={() => setIssueModal(false)}>
          <ToolIssuingForm
            onViewClose={() => {
              setIssueModal(false);
            }}
          />
        </Modal>
      )}

      {viewModal && (
        <Modal>
          <ToolRequestDetail
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
