import moment from "moment";

export const useTransformOption = (
  options,
  valueKey = "id",
  labelKey = "name"
) => {
  let arr = [];

  for (let i in options) {
    arr.push({
      value: options[i][valueKey],
      label: options[i][labelKey],
    });
  }

  return arr;
};

export const filterUnissuedTools = (options) => {
  let tools = [];
  if (!options) {
    return null;
  }

  options.forEach((item, index) => {
    let tool = tools.find((tItem) => tItem.description === item.description);
    if (!tool) {
      tools.push({
        value: item.id,
        description: item.description,
        label: `${item.description} (available: 1)`,
        quantity: 1,
      });
    } else {
      let toolIndex = tools.indexOf(tool);
      if (toolIndex > -1) {
        tools[toolIndex].quantity++;
        tools[
          toolIndex
        ].label = `${tools[toolIndex].description} (available: ${tools[toolIndex].quantity})`;
      }
    }
  });

  return tools;
};

export const transformUnissuedTools = (options) => {
  let tools = [];

  if (!options) {
    return null;
  }

  options.forEach((item) => {
    tools.push({
      id: item.id,
      value: item.id,
      description: item.description,
      serial_no: item.serial_no,
      condition: item.condition,
      brand: item.brand,
      purchase_date: item.purchase_date,
      warranty_expiry_date: item.warranty_expiry_date,
      label: `${item.description} (${item.condition}) (S/N: ${item.serial_no})`,
    });
  });

  return tools;
};

export const transformDate = (date, format) =>
  moment(date)
    .format(format || "YYYY-MM-DD")
    .toString();

export const groupBy = (array, group) => {
  var hash = Object.create(null),
    result = [];

  array.forEach(function (a) {
    if (!hash[a[group]]) {
      hash[a[group]] = [];
      result.push(hash[a[group]]);
    }
    hash[a[group]].push(a);
  });
  return result;
};
