import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Spinner,
  Pagination,
  Tab,
  EmptyPlaceholder,
  Label,
  Attachment,
} from "../common";
import {
  getDeliveredMaterials,
  getForDeliveryMaterials,
  getPartialDeliveredMaterials,
  selectDeliveredMaterial,
  selectForDeliveryMaterial,
  selectPartialDeliveredMaterial,
  deleteDeliveryMaterial,
} from "../../actions/deliverMaterials";
import { deleteIssuePoMaterial } from "../../actions/issuePoMaterials";
import { getProjects } from "../../actions/projects";
import { roles } from "../../helpers/userRoles";
import MaterialDeliveryForm from "./MaterialDeliveryForm";
import MaterialDeliveryPartialForm from "./MaterialDeliveryPartialForm";
import MaterialDeliveryDetail from "./MaterialDeliveryDetail";
import { useTransformOption } from "../../helpers/useTransformData";
import Select from "react-select";
import swal from "sweetalert";

export default function MaterialDeliveryPage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const projects = useSelector((state) => state.projects.projects);
  const projectsDropdown = useTransformOption(
    [{ id: null, title: "All" }, ...projects],
    "id",
    "title"
  );
  const deliveredMaterialsPagination = useSelector(
    (state) => state.deliverMaterials.pagination
  );
  const forDeliveryMaterials = useSelector(
    (state) => state.deliverMaterials.forDeliveryMaterials
  );
  const forDeliveryPagination = useSelector(
    (state) => state.deliverMaterials.forDeliveryPagination
  );
  const deliveredMaterials = useSelector(
    (state) => state.deliverMaterials.deliveredMaterials
  );
  const partialDeliveredMaterials = useSelector(
    (state) => state.deliverMaterials.partialDeliveredMaterials
  );
  const partialDeliveredPagination = useSelector(
    (state) => state.deliverMaterials.partialDeliveredPagination
  );
  const deliveredMaterialsIsLoading = useSelector(
    (state) => state.deliverMaterials.isLoading
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const [status, setStatus] = useState("For Delivery");
  const [addModal, setAddModal] = useState(false);
  const [partialModal, setPartialModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deliveredMaterialsPageNumber, setDeliveredMaterialsPageNumber] =
    useState(1);
  const [
    partialDeliveredMaterialsPageNumber,
    setPartialDeliveredMaterialsPageNumber,
  ] = useState(1);
  const [forDeliveryMaterialsPageNumber, setForDeliveryMaterialsPageNumber] =
    useState(1);

  const handleProjectChange = (option) => {
    setSelectedProject(option);
    if (status === "For Delivery") {
      setForDeliveryMaterialsPageNumber(1);
    } else if (status === "Partially Delivered") {
      setPartialDeliveredMaterialsPageNumber(1);
    } else {
      setDeliveredMaterialsPageNumber(1);
    }
  };

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (status === "For Delivery") {
      dispatch(
        getForDeliveryMaterials(
          forDeliveryMaterialsPageNumber,
          selectedProject ? selectedProject.value : null
        )
      );
    } else if (status === "Partially Delivered") {
      dispatch(
        getPartialDeliveredMaterials(
          partialDeliveredMaterialsPageNumber,
          selectedProject ? selectedProject.value : null
        )
      );
    } else {
      dispatch(
        getDeliveredMaterials(
          deliveredMaterialsPageNumber,
          selectedProject ? selectedProject.value : null
        )
      );
    }
  }, [
    status,
    dispatch,
    partialDeliveredMaterialsPageNumber,
    deliveredMaterialsPageNumber,
    forDeliveryMaterialsPageNumber,
    selectedProject,
  ]);

  return (
    <div className="mt-4">
      {addModal && (
        <Modal>
          <MaterialDeliveryForm
            onFormClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {partialModal && (
        <Modal>
          <MaterialDeliveryPartialForm
            onFormClose={() => {
              setPartialModal(false);
            }}
            onFormSubmit={() => {
              dispatch(
                getPartialDeliveredMaterials(
                  partialDeliveredMaterialsPageNumber,
                  selectedProject ? selectedProject.value : null
                )
              );
            }}
          />
        </Modal>
      )}

      <div className="mb-4">
        <Label name="Project" />
        <Select
          className="text-xs"
          options={projectsDropdown}
          value={selectedProject}
          onChange={handleProjectChange}
        />
      </div>

      <h1 className="font-bold mb-4">Select Delivery Status</h1>

      <div className="mb-4">
        <Tab>
          <Tab.Item
            name="For Delivery"
            isActive={status === "For Delivery"}
            onTabClick={() => {
              setStatus("For Delivery");
            }}
          />
          <Tab.Item
            name="Partially Delivered"
            isActive={status === "Partially Delivered"}
            onTabClick={() => {
              setStatus("Partially Delivered");
            }}
          />
          <Tab.Item
            name="Delivered"
            isActive={status === "Delivered"}
            onTabClick={() => {
              setStatus("Delivered");
            }}
          />
        </Tab>
      </div>

      {status === "For Delivery" && (
        <>
          {deliveredMaterialsIsLoading && <Spinner />}

          {!deliveredMaterialsIsLoading && (
            <table className="table-fixed w-100">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Issue PO Date</th>
                  <th className="text-xs px-4 py-2">Issue PO S/N</th>
                  <th className="text-xs px-4 py-2">
                    Expected <br /> Delivery Date
                  </th>
                  <th className="text-xs px-4 py-2">Supplier/Vendor</th>
                  <th className="text-xs px-4 py-2">Ship To</th>
                  {/* <th className="text-xs px-4 py-2">No. of Materials</th> */}
                  <th className="text-xs px-4 py-2">Requested By</th>
                  <th className="text-xs px-4 py-2">Warehouse</th>
                  <th className="text-xs px-4 py-2">Issued By</th>
                  <th className="text-xs px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {forDeliveryMaterials.map((forDeliveryMaterial) => (
                  <tr
                    key={forDeliveryMaterial.id}
                    className="hover:bg-gray-200"
                  >
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.ref_no
                        ? forDeliveryMaterial.ref_no
                        : `${
                            forDeliveryMaterial.request_purchase_order
                              ? forDeliveryMaterial.request_purchase_order
                                  .series_no
                              : "-"
                          } (RO No.)`}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.request_purchase_order
                        ? forDeliveryMaterial.request_purchase_order
                            .delivery_date
                        : "-"}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.supplier ? (
                        <>
                          <div className="flex items-center">
                            <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={
                                  forDeliveryMaterial.supplier.photo_full_url
                                }
                              />
                            </span>
                            <span className="text-xs ml-2">
                              {forDeliveryMaterial.supplier.name}
                              <br />
                              {forDeliveryMaterial.supplier.contact_person}
                              <br />
                              {forDeliveryMaterial.supplier.contact_number}
                              <br />
                              {forDeliveryMaterial.supplier.address}
                              <br />
                            </span>
                          </div>
                        </>
                      ) : (
                        " - "
                      )}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.ship_to ? (
                        <>
                          <div className="flex items-center">
                            <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                              {forDeliveryMaterial.ship_to.avatar && (
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={forDeliveryMaterial.ship_to.avatar.image}
                                />
                              )}
                            </span>
                            <span className="text-xs ml-2">
                              {forDeliveryMaterial.ship_to.first_name}{" "}
                              {forDeliveryMaterial.ship_to.last_name}
                              <br />
                              {forDeliveryMaterial.ship_to.contact_number}
                              <br />
                              {forDeliveryMaterial.ship_to_address}
                              <br />
                            </span>
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    {/* <td className="text-xs border px-2 py-2">
                    {forDeliveryMaterial.request_purchase_order.materials
                      ? `${forDeliveryMaterial.request_purchase_order.materials.length}`
                      : "-"}
                  </td> */}
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.request_purchase_order &&
                      forDeliveryMaterial.request_purchase_order
                        .requested_by ? (
                        <>
                          <div className="flex items-center">
                            <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                              {forDeliveryMaterial.request_purchase_order
                                .requested_by.avatar && (
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={
                                    forDeliveryMaterial.request_purchase_order
                                      .requested_by.avatar.image
                                  }
                                />
                              )}
                            </span>
                            <span className="text-xs ml-2">
                              {
                                forDeliveryMaterial.request_purchase_order
                                  .requested_by.first_name
                              }{" "}
                              {
                                forDeliveryMaterial.request_purchase_order
                                  .requested_by.last_name
                              }
                              <br />
                              {
                                forDeliveryMaterial.request_purchase_order
                                  .requested_by.contact_number
                              }
                              <br />
                            </span>
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.warehouse
                        ? forDeliveryMaterial.warehouse.name
                        : "-"}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {forDeliveryMaterial.issued_by ? (
                        <>
                          <div className="flex items-center">
                            <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                              {forDeliveryMaterial.issued_by.avatar && (
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={
                                    forDeliveryMaterial.issued_by.avatar.image
                                  }
                                />
                              )}
                            </span>
                            <span className="text-xs ml-2">
                              {forDeliveryMaterial.issued_by.first_name}{" "}
                              {forDeliveryMaterial.issued_by.last_name}
                              <br />
                              {forDeliveryMaterial.issued_by.contact_number}
                              <br />
                            </span>
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      <div className="flex">
                        <div className="flex-initial">
                          <Button
                            text={`Process Goods Delivered`}
                            warning="true"
                            onClick={() => {
                              dispatch(
                                selectForDeliveryMaterial(forDeliveryMaterial)
                              );
                              setAddModal(true);
                            }}
                          />
                        </div>
                        <div className="flex-initial">
                          {user.employee.role_id === roles.su && (
                            <Button
                              text={`Delete`}
                              danger="true"
                              onClick={() => {
                                swal({
                                  icon: "error",
                                  title: "Are you sure?",
                                  text: `This will delete this Issue Purchase Order.`,
                                  buttons: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    dispatch(
                                      deleteIssuePoMaterial(
                                        forDeliveryMaterial.id
                                      )
                                    );
                                  }
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {forDeliveryMaterials < 1 && (
            <EmptyPlaceholder text="Nothing For Delivery" />
          )}

          {forDeliveryPagination && !deliveredMaterialsIsLoading && (
            <Pagination
              from={forDeliveryPagination.from}
              to={forDeliveryPagination.to}
              total={forDeliveryPagination.total}
              lastPage={forDeliveryPagination.last_page}
              currentPage={forDeliveryPagination.current_page}
              onPageClick={(page) => {
                setForDeliveryMaterialsPageNumber(page);
              }}
            />
          )}
        </>
      )}

      {status === "Partially Delivered" && (
        <>
          {deliveredMaterialsIsLoading && <Spinner />}
          {!deliveredMaterialsIsLoading && (
            <>
              <table className="table-fixed w-100">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">PO Date</th>
                    <th className="text-xs px-4 py-2">Ref No.</th>
                    <th className="text-xs px-4 py-2">
                      Expected <br /> Delivery Date
                    </th>
                    <th className="text-xs px-4 py-2">Supplier/Vendor</th>
                    <th className="text-xs px-4 py-2">Ship To</th>
                    <th className="text-xs px-4 py-2">Requested By</th>
                    <th className="text-xs px-4 py-2">Warehouse</th>
                    <th className="text-xs px-4 py-2">Issued By</th>
                    <th className="text-xs px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {partialDeliveredMaterials.map((partialDeliveredMaterial) => (
                    <tr
                      key={partialDeliveredMaterial.id}
                      className="hover:bg-gray-200"
                    >
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.request_purchase_order
                          ? partialDeliveredMaterial.request_purchase_order
                              .series_no
                          : "-"}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.request_purchase_order
                          ? partialDeliveredMaterial.request_purchase_order
                              .delivery_date
                          : "-"}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.supplier ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={
                                    partialDeliveredMaterial.supplier
                                      .photo_full_url
                                  }
                                />
                              </span>
                              <span className="text-xs ml-2">
                                {partialDeliveredMaterial.supplier.name}
                                <br />
                                {
                                  partialDeliveredMaterial.supplier
                                    .contact_person
                                }
                                <br />
                                {
                                  partialDeliveredMaterial.supplier
                                    .contact_number
                                }
                                <br />
                                {partialDeliveredMaterial.supplier.address}
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          " - "
                        )}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.ship_to ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                {partialDeliveredMaterial.ship_to.avatar && (
                                  <img
                                    alt="Supplier"
                                    className="rounded-full border border-gray-100 object-cover"
                                    src={
                                      partialDeliveredMaterial.ship_to.avatar
                                        .image
                                    }
                                  />
                                )}
                              </span>
                              <span className="text-xs ml-2">
                                {partialDeliveredMaterial.ship_to.first_name}{" "}
                                {partialDeliveredMaterial.ship_to.last_name}
                                <br />
                                {
                                  partialDeliveredMaterial.ship_to
                                    .contact_number
                                }
                                <br />
                                {partialDeliveredMaterial.ship_to_address}
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      {/* <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.request_purchase_order.materials
                      ? `${partialDeliveredMaterial.request_purchase_order.materials.length}`
                      : "-"}
                  </td> */}
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.request_purchase_order &&
                        partialDeliveredMaterial.request_purchase_order
                          .requested_by ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                {partialDeliveredMaterial.request_purchase_order
                                  .requested_by.avatar && (
                                  <img
                                    alt="Supplier"
                                    className="rounded-full border border-gray-100 object-cover"
                                    src={
                                      partialDeliveredMaterial
                                        .request_purchase_order.requested_by
                                        .avatar.image
                                    }
                                  />
                                )}
                              </span>
                              <span className="text-xs ml-2">
                                {
                                  partialDeliveredMaterial
                                    .request_purchase_order.requested_by
                                    .first_name
                                }{" "}
                                {
                                  partialDeliveredMaterial
                                    .request_purchase_order.requested_by
                                    .last_name
                                }
                                <br />
                                {
                                  partialDeliveredMaterial
                                    .request_purchase_order.requested_by
                                    .contact_number
                                }
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.warehouse
                          ? partialDeliveredMaterial.warehouse.name
                          : "-"}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {partialDeliveredMaterial.issued_by ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                {partialDeliveredMaterial.issued_by.avatar && (
                                  <img
                                    alt="Supplier"
                                    className="rounded-full border border-gray-100 object-cover"
                                    src={
                                      partialDeliveredMaterial.issued_by.avatar
                                        .image
                                    }
                                  />
                                )}
                              </span>
                              <span className="text-xs ml-2">
                                {partialDeliveredMaterial.issued_by.first_name}{" "}
                                {partialDeliveredMaterial.issued_by.last_name}
                                <br />
                                {
                                  partialDeliveredMaterial.issued_by
                                    .contact_number
                                }
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        <div className="flex">
                          <div className="flex-initial">
                            <Button
                              text={`Process Goods Delivered`}
                              warning="true"
                              onClick={() => {
                                dispatch(
                                  selectPartialDeliveredMaterial(
                                    partialDeliveredMaterial
                                  )
                                );
                                setPartialModal(true);
                              }}
                            />
                          </div>
                          <div className="flex-initial">
                            {user.employee.role_id === roles.su && (
                              <Button
                                text={`Delete`}
                                danger="true"
                                onClick={() => {
                                  swal({
                                    icon: "error",
                                    title: "Are you sure?",
                                    text: `This will delete this Deliver PO.`,
                                    buttons: true,
                                  }).then(async (willDelete) => {
                                    if (willDelete) {
                                      dispatch(
                                        deleteIssuePoMaterial(
                                          partialDeliveredMaterial.id
                                        )
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

          {partialDeliveredMaterials < 1 && (
            <EmptyPlaceholder text="Nothing Partially Delivered" />
          )}

          {partialDeliveredPagination && !deliveredMaterialsIsLoading && (
            <Pagination
              from={partialDeliveredPagination.from}
              to={partialDeliveredPagination.to}
              total={partialDeliveredPagination.total}
              lastPage={partialDeliveredPagination.last_page}
              currentPage={partialDeliveredPagination.current_page}
              onPageClick={(page) => {
                setPartialDeliveredMaterialsPageNumber(page);
              }}
            />
          )}
        </>
      )}

      {status === "Delivered" && (
        <>
          {deliveredMaterialsIsLoading && <Spinner />}

          {!deliveredMaterialsIsLoading && (
            <table className="table-fixed w-100">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date Delivered</th>
                  <th className="text-xs px-4 py-2">Issue PO #</th>
                  <th className="text-xs px-4 py-2">Supplier/Vendor</th>
                  <th className="text-xs px-4 py-2">Ship To</th>
                  <th className="text-xs px-4 py-2">Issued By</th>
                  <th className="text-xs px-4 py-2">Warehouse</th>
                  <th className="text-xs px-4 py-2">Received By</th>
                  <th className="text-xs px-4 py-2">Attachment</th>
                  <th className="text-xs px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {deliveredMaterials.map((deliverMaterial) => (
                  <tr key={deliverMaterial.id} className="hover:bg-gray-200">
                    <td className="text-xs border px-2 py-2">
                      {deliverMaterial.delivered_date}
                    </td>
                    <>
                      <td className="text-xs border px-2 py-2">
                        {deliverMaterial.issue_purchase_order?.ref_no}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {deliverMaterial.issue_purchase_order?.supplier ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={
                                    deliverMaterial.issue_purchase_order
                                      ?.supplier.photo_full_url
                                  }
                                />
                              </span>
                              <span className="text-xs ml-2">
                                {
                                  deliverMaterial.issue_purchase_order?.supplier
                                    .name
                                }
                                <br />
                                {
                                  deliverMaterial.issue_purchase_order?.supplier
                                    .contact_person
                                }
                                <br />
                                {
                                  deliverMaterial.issue_purchase_order?.supplier
                                    .contact_number
                                }
                                <br />
                                {
                                  deliverMaterial.issue_purchase_order?.supplier
                                    .address
                                }
                                <br />
                              </span>
                            </div>
                          </>
                        ) : deliverMaterial.supplier ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={deliverMaterial.supplier.photo_full_url}
                                />
                              </span>
                              <span className="text-xs ml-2">
                                {deliverMaterial.supplier.name}
                                <br />
                                {deliverMaterial.supplier.contact_person}
                                <br />
                                {deliverMaterial.supplier.contact_number}
                                <br />
                                {deliverMaterial.supplier.address}
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          " - "
                        )}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {deliverMaterial.issue_purchase_order?.ship_to ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                {deliverMaterial.issue_purchase_order?.ship_to
                                  .avatar && (
                                  <img
                                    alt="Supplier"
                                    className="rounded-full border border-gray-100 object-cover"
                                    src={
                                      deliverMaterial.issue_purchase_order
                                        ?.ship_to.avatar.image
                                    }
                                  />
                                )}
                              </span>
                              <span className="text-xs ml-2">
                                {
                                  deliverMaterial.issue_purchase_order?.ship_to
                                    .first_name
                                }{" "}
                                {
                                  deliverMaterial.issue_purchase_order?.ship_to
                                    .last_name
                                }
                                <br />
                                {
                                  deliverMaterial.issue_purchase_order?.ship_to
                                    .contact_number
                                }
                                <br />
                                {
                                  deliverMaterial.issue_purchase_order
                                    .ship_to_address
                                }
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {deliverMaterial.issue_purchase_order?.issued_by ? (
                          <>
                            <div className="flex items-center">
                              <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                                {deliverMaterial.issue_purchase_order?.issued_by
                                  .avatar && (
                                  <img
                                    alt="Supplier"
                                    className="rounded-full border border-gray-100 object-cover"
                                    src={
                                      deliverMaterial.issue_purchase_order
                                        ?.issued_by.avatar.image
                                    }
                                  />
                                )}
                              </span>
                              <span className="text-xs ml-2">
                                {
                                  deliverMaterial.issue_purchase_order
                                    ?.issued_by.first_name
                                }{" "}
                                {
                                  deliverMaterial.issue_purchase_order
                                    ?.issued_by.last_name
                                }
                                <br />
                                {
                                  deliverMaterial.issue_purchase_order
                                    ?.issued_by.contact_number
                                }
                                <br />
                              </span>
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {deliverMaterial.issue_purchase_order?.warehouse
                          ? deliverMaterial.issue_purchase_order?.warehouse.name
                          : "-"}
                      </td>
                    </>
                    <td className="text-xs border px-2 py-2">
                      {deliverMaterial.received_by ? (
                        <>
                          <div className="flex items-center">
                            <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                              {deliverMaterial.received_by.avatar && (
                                <img
                                  alt="Supplier"
                                  className="rounded-full border border-gray-100 object-cover"
                                  src={deliverMaterial.received_by.avatar.image}
                                />
                              )}
                            </span>
                            <span className="text-xs ml-2">
                              {deliverMaterial.received_by.first_name}{" "}
                              {deliverMaterial.received_by.last_name}
                              <br />
                              {deliverMaterial.received_by.contact_number}
                              <br />
                            </span>
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {deliverMaterial.attachment ? (
                        <Attachment
                          path={deliverMaterial.attachment_full_url}
                        />
                      ) : (
                        <p className="text-gray-600 text-xs">No attachments.</p>
                      )}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      <div className="flex">
                        <div className="flex-initial">
                          <Button
                            primary="true"
                            text="View"
                            onClick={() => {
                              dispatch(
                                selectDeliveredMaterial(deliverMaterial)
                              );
                              setViewModal(true);
                            }}
                          />
                        </div>
                        <div className="flex-initial">
                          {user.employee.role_id === roles.su && (
                            <Button
                              text={`Delete`}
                              danger="true"
                              onClick={() => {
                                swal({
                                  icon: "error",
                                  title: "Are you sure?",
                                  text: `This will delete this Deliver PO.`,
                                  buttons: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    dispatch(
                                      deleteDeliveryMaterial(deliverMaterial.id)
                                    );
                                    getDeliveredMaterials(
                                      deliveredMaterialsPageNumber,
                                      selectedProject
                                        ? selectedProject.value
                                        : null
                                    );
                                  }
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {deliveredMaterialsPagination && !deliveredMaterialsIsLoading && (
            <Pagination
              from={deliveredMaterialsPagination.from}
              to={deliveredMaterialsPagination.to}
              total={deliveredMaterialsPagination.total}
              lastPage={deliveredMaterialsPagination.last_page}
              currentPage={deliveredMaterialsPagination.current_page}
              onPageClick={(page) => {
                setDeliveredMaterialsPageNumber(page);
              }}
            />
          )}
        </>
      )}

      {viewModal && (
        <Modal>
          <MaterialDeliveryDetail
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
