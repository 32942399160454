import {
  GET_DOCUMENTATION_NAVIGATIONS,
  GET_DOCUMENTATIONS,
  SELECT_DOCUMENTATION,
  LOADING_DOCUMENTATIONS,
  LOADING_DOCUMENTATION_FORM,
  ADD_DOCUMENTATION,
  APPROVE_REJECT_DOCUMENTATION,
  UPDATE_DOCUMENTATION,
  DELETE_DOCUMENTATION,
  LOADING_MANPOWER_COUNT,
  GET_MANPOWER_COUNT,
} from "../actions/types";

const initialState = {
  documentations: [],
  documentationNavigations: [],
  selectedDocument: null,
  isLoading: false,
  isFormLoading: false,
  isLoadingManpowerCount: false,
  manpowerCount: 0,
  manpowerBreakdown: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DOCUMENTATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_DOCUMENTATION_FORM:
      return {
        ...state,
        isFormLoading: true,
      };
    case LOADING_MANPOWER_COUNT:
      return {
        ...state,
        isLoadingManpowerCount: true,
      };
    case APPROVE_REJECT_DOCUMENTATION:
      return {
        ...state,
        isFormLoading: false,
      };
    case GET_DOCUMENTATION_NAVIGATIONS:
      return {
        ...state,
        documentationNavigations: action.payload,
        isLoading: false,
      };
    case GET_DOCUMENTATIONS:
      return {
        ...state,
        documentations: action.payload,
        isLoading: false,
      };
    case SELECT_DOCUMENTATION:
      return {
        ...state,
        selectedDocument: action.payload,
      };
    case ADD_DOCUMENTATION:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_DOCUMENTATION:
      return {
        ...state,
        documentations: state.documentations.map((document) => {
          if (action.payload.id !== document.id) {
            return document;
          }

          delete action.payload.created_by;
          delete action.payload.approved_by;

          return {
            ...document,
            ...action.payload,
          };
        }),
      };
    case DELETE_DOCUMENTATION:
      return {
        ...state,
        documentations: state.documentations.filter(
          (document) => document.id !== action.payload
        ),
      };
    case GET_MANPOWER_COUNT:
      return {
        ...state,
        isLoadingManpowerCount: false,
        manpowerCount: action.payload,
      };
    default:
      return state;
  }
}
