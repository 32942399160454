import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import ImageUploader from "react-images-upload";
import swal from "sweetalert";
import { addDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";

export default function MyTasksAddToolboxMeeting(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [photos, setPhotos] = useState([]);
  const imageUploadRef = useRef(null);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);

  //Values to add
  const [safety, setSafety] = useState(0);
  const [supervisor, setSupervisor] = useState(0);
  const [worker, setWorker] = useState(0);
  const [subcon, setSubcon] = useState(0);
  const [supplyLabour, setSupplyLabour] = useState(0);

  const onDrop = (photo) => {
    setPhotos(photo);
  };

  useEffect(() => {
    register("date");
  }, [register]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add Toolbox Meeting to the Project.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let tempImg = [];
        for (let i = 0; i < photos.length; i++) {
          tempImg.push(await ImageUtils.resizeImage(photos[i]));
        }
        if (tempImg.length > 0) {
          data.base64image = tempImg;
        }
        data.type = "toolbox";
        data.total =
          parseInt(safety || 0) +
          parseInt(supervisor || 0) +
          parseInt(worker || 0) +
          parseInt(subcon || 0) +
          parseInt(supplyLabour || 0);

        dispatch(
          addDocumentation(selectedTask.id, "projects", data, selectedTask)
        );
        props.onTaskSubmit && props.onTaskSubmit();
        e.target.reset();
      }
    });
  };

  setValue("date", moment(new Date()).format("YYYY-MM-DD").toString());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">Add New Toolbox Meeting</h1>
      <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>

      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
        </div>

        <div className="flex">
          <div className="w-1/3">
            <Label name="Safety" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="safety"
              type="number"
              value={safety}
              onChange={(e) => setSafety(e.target.value)}
              required
            />
          </div>
          <div className="w-1/3 px-4">
            <Label name="Supervisor" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="supervisor"
              type="number"
              value={supervisor}
              onChange={(e) => setSupervisor(e.target.value)}
              required
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Worker" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="worker"
              type="number"
              value={worker}
              onChange={(e) => setWorker(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="flex mt-4">
          <div className="w-1/3">
            <Label name="Subcon" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subcon"
              type="number"
              value={subcon}
              onChange={(e) => setSubcon(e.target.value)}
              required
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Supply Labour" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="supply_labour"
              type="number"
              value={supplyLabour}
              onChange={(e) => setSupplyLabour(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="flex">
          <div className="w-1/3 mt-4">
            <Label name="Total" />
            <p className="text-sm font-bold">
              {parseInt(safety || 0) +
                parseInt(supervisor || 0) +
                parseInt(worker || 0) +
                parseInt(subcon || 0) +
                parseInt(supplyLabour || 0)}
            </p>
          </div>
        </div>

        <div className="mt-4">
          <Label name="Photos" />
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>
      <Button
        text="Close"
        primary="true"
        // disabled={isFormLoading}
        onClick={() => {
          props.onTaskSubmit && props.onTaskSubmit();
        }}
      />
      <Button text="Save" success="true" type="submit" />
    </form>
  );
}
