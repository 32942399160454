import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button, Avatar } from "../common";
import swal from "sweetalert";
import {
  updateRequestPoMaterialStatus,
  generatePDF,
} from "../../actions/requestPoMaterials";
import { roles } from "../../helpers/userRoles";
import MaterialIcon from "../../assets/img/material_icon.png";
import "react-datepicker/dist/react-datepicker.css";

export default function MaterialRequestOrderView(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isPDFLoading = useSelector(
    (state) => state.requestPoMaterials.isPDFLoading
  );
  const selectedRequestPo = useSelector(
    (state) => state.requestPoMaterials.selected
  );

  const onUpdateStatus = (status) => {
    swal({
      icon: "warning",
      title: `${
        status === "Approved1" || status === "Approved2" ? "Approve" : status
      } Request PO?`,
      text: `Are you sure?`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let data = {
          id: selectedRequestPo.id,
          status,
        };

        if (props.view === "my-tasks") {
          data.approved_on_site_by = user.employee.id;
          data.approved_by = null;
        }

        if (props.view === "materials") {
          data.approved_by = user.employee.id;
          data.approved_on_site_by = null;
        }

        dispatch(updateRequestPoMaterialStatus(data));
        props.onFormClose && props.onFormClose();
      }
    });
  };

  return (
    <form>
      <h1 className="font-bold">View Request Order</h1>
      <div className="flex-initial">
        <Button
          text={`Generate PDF`}
          primary="true"
          disabled={isPDFLoading}
          onClick={() => {
            dispatch(generatePDF(selectedRequestPo.id));
          }}
        />
      </div>

      <div className="grid mt-4">
        <div className="flex">
          <div className="w-1/2">
            <Label name="Request PO Series No." />
            <p className="text-xs">{selectedRequestPo.series_no}</p>
          </div>
          <div className="w-1/2 px-4">
            <Label name="Project/Task" />
            <p className="text-xs">
              {selectedRequestPo.task
                ? selectedRequestPo.task.heirarchy
                : selectedRequestPo.project.heirarchy}
            </p>
          </div>
        </div>
        <div className="flex mt-4">
          <div className="w-1/2">
            <Label name="Status" />
            <p className="text-xs">
              {selectedRequestPo.status === "Approved1"
                ? "Approved on site. Awaiting for Procurement Officer's approval."
                : selectedRequestPo.status === "Approved2"
                ? "Approved by Procurement Officer."
                : selectedRequestPo.status}
            </p>
          </div>

          <div className="w-1/2 px-4">
            <Label name="Requested By" />
            {selectedRequestPo.requested_by ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    {selectedRequestPo.requested_by.avatar && (
                      <img
                        alt="Supplier"
                        className="rounded-full border border-gray-100 object-cover"
                        src={selectedRequestPo.requested_by.avatar.image}
                      />
                    )}
                  </span>
                  <span className="text-xs ml-2">
                    {selectedRequestPo.requested_by.first_name}{" "}
                    {selectedRequestPo.requested_by.last_name}
                    <br />
                    {selectedRequestPo.requested_by.contact_number}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex mt-4">
          <div className="w-1/2">
            <Label name="Approved on site by" />
            {selectedRequestPo.approved_on_site_by ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    {selectedRequestPo.approved_on_site_by.avatar && (
                      <img
                        alt="Supplier"
                        className="rounded-full border border-gray-100 object-cover"
                        src={selectedRequestPo.approved_on_site_by.avatar.image}
                      />
                    )}
                  </span>
                  <span className="text-xs ml-2">
                    {selectedRequestPo.approved_on_site_by.first_name}{" "}
                    {selectedRequestPo.approved_on_site_by.last_name}
                    <br />
                    {selectedRequestPo.approved_on_site_by.contact_number}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="w-1/2 px-4">
            <Label name="Approved by" />
            {selectedRequestPo.approved_by ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    {selectedRequestPo.approved_by.avatar && (
                      <img
                        alt="Supplier"
                        className="rounded-full border border-gray-100 object-cover"
                        src={selectedRequestPo.approved_by.avatar.image}
                      />
                    )}
                  </span>
                  <span className="text-xs ml-2">
                    {selectedRequestPo.approved_by.first_name}{" "}
                    {selectedRequestPo.approved_by.last_name}
                    <br />
                    {selectedRequestPo.approved_by.contact_number}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <p className="text-sm font-bold mt-4">Materials Requested</p>
      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Photo</th>
            <th className="text-xs px-4 py-2">Type</th>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
            <th className="text-xs px-4 py-2">Supplier</th>
            <th className="text-xs px-4 py-2">Order Qty</th>
            <th className="text-xs px-4 py-2">
              Expected
              <br />
              Delivery Date
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedRequestPo.materials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                  {material.photo_full_url ? (
                    <img
                      alt="..."
                      className="w-full align-middle border-none"
                      src={material.photo_full_url}
                    />
                  ) : (
                    <img
                      alt="..."
                      className="w-full align-middle border-none"
                      src={MaterialIcon}
                    />
                  )}
                </span>
              </td>
              <td className="text-xs border px-2 py-2">{material.type}</td>
              <td className="text-xs border px-2 py-2">
                {material.category?.name}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              {/* <td className="text-xs border px-2 py-2">
                {material.warehouse ? material.warehouse.name : ""}
              </td> */}
              <td className="text-xs border px-2 py-2">
                {material.supplier ? (
                  <Avatar
                    name={material.supplier.name}
                    image={material.supplier.photo_full_url}
                  />
                ) : (
                  ""
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                {material.pivot.order_qty} {material.unit}
              </td>
              <td className="text-xs border px-2 py-2">
                {material.pivot.delivery_date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {props.view === "materials" && (
        <>
          <p className="text-xs text-gray-600 mt-4 mb-4">Procurement Actions</p>
          {selectedRequestPo.status !== "Approved2" && (
            <Button
              text="Approve"
              success="true"
              onClick={() => {
                onUpdateStatus("Approved2");
              }}
            />
          )}

          {selectedRequestPo.status !== "Approved2" && (
            <Button
              text="Reject"
              danger="true"
              onClick={() => {
                onUpdateStatus("Rejected");
              }}
            />
          )}
        </>
      )}

      {props.view === "my-tasks" && (
        <>
          <p className="text-xs text-gray-600 mt-4 mb-4">On-site Actions</p>
          {(user.employee.role_id === roles.su ||
            user.employee.role_id === roles.dir ||
            user.employee.role_id === roles.engr ||
            user.employee.role_id === roles.pm ||
            user.employee.role_id === roles.apm) && (
            <>
              {selectedRequestPo.status !== "Approved1" &&
                selectedRequestPo.status !== "Approved2" && (
                  <Button
                    text="Approve"
                    success="true"
                    onClick={() => {
                      onUpdateStatus("Approved1");
                    }}
                  />
                )}

              {selectedRequestPo.status !== "Approved1" &&
                selectedRequestPo.status !== "Approved2" && (
                  <Button
                    text="Reject"
                    danger="true"
                    onClick={() => {
                      onUpdateStatus("Rejected");
                    }}
                  />
                )}
            </>
          )}

          {selectedRequestPo.status === "Approved1" && (
            <p className="text-xs text-gray-600 mb-4">
              Already approved on site. Waiting for Procurement Officer's
              approval.
            </p>
          )}

          {selectedRequestPo.status === "Approved2" && (
            <p className="text-xs text-gray-600 mb-4">
              Already approved by the Procurement Officer.
            </p>
          )}
        </>
      )}

      <Button
        text="Close"
        primary="true"
        onClick={() => {
          props.onFormClose && props.onFormClose();
        }}
      />
    </form>
  );
}
