import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import swal from "sweetalert";
import { roles } from "../../helpers/userRoles";
import filterManpower from "../../helpers/filterManpower";
import dropdownEmployees from "../../helpers/dropdownEmployees";
import { getUnassignedEmployees } from "../../actions/employees";
import { assignToTask, unassignToTask } from "../../actions/tasks";
import { assignToProject, unassignToProject } from "../../actions/projects";

import classNames from "classnames";

import { Avatar, Button, Modal, StyledDialog } from "../common";
import { ASSIGN_TO_TASK, UNASSIGN_TO_TASK } from "actions/types";

export default function MyTasksManpower(props) {
  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const selectedMyTask = useSelector((state) => state.tasks.selectedMyTask);
  const user = useSelector((state) => state.auth.user);
  let manpower = filterManpower(
    selectedTask.workers,
    selectedTask.assignee ? selectedTask.assignee[0] : 0,
    selectedTask.pic
  );

  const [engineers, setEngineers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [safeties, setSafeties] = useState([]);

  const [selectedEngineer, setSelectedEngineer] = useState({
    value: 0,
    label: "Select an Engineer...",
  });
  const [selectedSupervisor, setSelectedSupervisor] = useState({
    value: 0,
    label: "Select a Supervisor...",
  });
  const [selectedWorker, setSelectedWorker] = useState({
    value: 0,
    label: "Select a Worker...",
  });
  const [selectedSafety, setSelectedSafety] = useState({
    value: 0,
    label: "Select Safety Personnel...",
  });
  const [selectedPIC, setSelectedPIC] = useState({
    value: 0,
    label: "Select a PIC...",
  });
  const [selectedCoordinator, setSelectedCoordinator] = useState({
    value: 0,
    label: "Select a Coordinator...",
  });

  const [modalEngineersOpen, setModalEngineersOpen] = useState(false);
  const [modalPICOpen, setModalPICOpen] = useState(false);
  const [modalSafetyOpen, setModalSafetyOpen] = useState(false);
  const [modalCoordinator, setModalCoordinatorOpen] = useState(false);
  const [modalSupervisorsOpen, setModalSupervisorsOpen] = useState(false);
  const [modalWorkersOpen, setModalWorkersOpen] = useState(false);

  const fillManpower = () => {
    manpower = filterManpower(
      selectedTask.workers,
      selectedTask.assignee ? selectedTask.assignee[0] : 0,
      selectedTask.pic
    );
  };

  useEffect(() => {
    //Get Unassigned Engineers
    dispatch(
      getUnassignedEmployees(
        selectedTask.id,
        selectedTask.type.slice(0, -1),
        null,
        [roles.engr, roles.coord]
      )
    )
      .then((res) => {
        setEngineers(res.data);
      })
      .catch((err) => console.error(err));

    //Get Unassigned Coordinators
    dispatch(
      getUnassignedEmployees(
        selectedTask.id,
        selectedTask.type.slice(0, -1),
        roles.coord
      )
    )
      .then((res) => {
        setCoordinators(res.data);
      })
      .catch((err) => console.error(err));

    //Get Unassigned Supervisors
    dispatch(
      getUnassignedEmployees(
        selectedTask.id,
        selectedTask.type.slice(0, -1),
        roles.sup
      )
    )
      .then((res) => {
        setSupervisors(res.data);
      })
      .catch((err) => console.error(err));

    //Get Unassigned Workers
    dispatch(
      getUnassignedEmployees(
        selectedTask.id,
        selectedTask.type.slice(0, -1),
        roles.wrkr
      )
    )
      .then((res) => {
        setWorkers(res.data);
      })
      .catch((err) => console.error(err));

    //Get Unassigned Safety Workers
    dispatch(
      getUnassignedEmployees(
        selectedTask.id,
        selectedTask.type.slice(0, -1),
        roles.sfty
      )
    )
      .then((res) => {
        setSafeties(res.data);
      })
      .catch((err) => console.error(err));
  }, [dispatch, selectedTask]);

  const saveEngineer = () => {
    let selected = engineers.find((worker) => {
      return worker.id === selectedEngineer.value;
    });
    if (selectedTask.type === "tasks") {
      dispatch(assignToTask(selected.id, [selectedTask.id], selectedMyTask));
    } else {
      dispatch(assignToProject(selected.id, [selectedTask.id], "true"));
      dispatch({
        type: ASSIGN_TO_TASK,
        payload: selected,
      });
    }
    setSelectedEngineer({
      value: 0,
      label: "Select an Engineer/Coordinator...",
    });
    fillManpower();
    setModalEngineersOpen(false);
  };

  const savePIC = () => {
    let selected = engineers.find((worker) => {
      return worker.id === selectedPIC.value;
    });

    if (selectedTask.type === "tasks") {
      dispatch(
        assignToTask(
          selected.id,
          [selectedTask.id],
          selectedMyTask,
          "false",
          "true"
        )
      );
    } else {
      dispatch(assignToProject(selected.id, [selectedTask.id], "true", "true"));
      dispatch({
        type: ASSIGN_TO_TASK,
        payload: selected,
      });
    }
    setSelectedPIC({
      value: 0,
      label: "Select a PIC...",
    });
    fillManpower();
    setModalPICOpen(false);
  };

  const saveCoordinator = () => {
    let selected = coordinators.find((worker) => {
      return worker.id === selectedCoordinator.value;
    });

    if (selectedTask.type === "tasks") {
      dispatch(assignToTask(selected.id, [selectedTask.id], selectedMyTask));
    } else {
      dispatch(assignToProject(selected.id, [selectedTask.id], "true"));
      dispatch({
        type: ASSIGN_TO_TASK,
        payload: selected,
      });
    }
    setSelectedCoordinator({
      value: 0,
      label: "Select a Coordinator...",
    });
    fillManpower();
    setModalCoordinatorOpen(false);
  };

  const saveSupervisor = () => {
    let selected = supervisors.find((worker) => {
      return worker.id === selectedSupervisor.value;
    });
    if (selectedTask.type === "tasks") {
      dispatch(assignToTask(selected.id, [selectedTask.id], selectedMyTask));
    } else {
      dispatch(assignToProject(selected.id, [selectedTask.id], "true"));
      dispatch({
        type: ASSIGN_TO_TASK,
        payload: selected,
      });
    }
    setSelectedSupervisor({
      value: 0,
      label: "Select a Supervisor...",
    });
    fillManpower();
    setModalSupervisorsOpen(false);
  };

  const saveWorker = () => {
    let selected = workers.find((worker) => {
      return worker.id === selectedWorker.value;
    });
    if (selectedTask.type === "tasks") {
      dispatch(assignToTask(selected.id, [selectedTask.id], selectedMyTask));
    } else {
      dispatch(assignToProject(selected.id, [selectedTask.id], "true"));
      dispatch({
        type: ASSIGN_TO_TASK,
        payload: selected,
      });
    }
    setSelectedWorker({
      value: 0,
      label: "Select a Worker...",
    });
    fillManpower();
    setModalWorkersOpen(false);
  };

  const saveSafety = () => {
    let selected = safeties.find((safety) => {
      return safety.id === selectedSafety.value;
    });

    if (selectedTask.type === "tasks") {
      dispatch(assignToTask(selected.id, [selectedTask.id], selectedMyTask));
    } else {
      dispatch(assignToProject(selected.id, [selectedTask.id], "true"));
      dispatch({
        type: ASSIGN_TO_TASK,
        payload: selected,
      });
    }
    setSelectedWorker({
      value: 0,
      label: "Select Safety Personnel...",
    });
    fillManpower();
    setModalSafetyOpen(false);
  };

  const removeEmployee = (employee) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will unassign employee to the task`,
      buttons: true,
    }).then((willRemove) => {
      if (willRemove) {
        if (selectedTask.type === "tasks") {
          dispatch(
            unassignToTask(employee.id, [selectedTask.id], selectedMyTask)
          );
        } else {
          dispatch(unassignToProject(employee.id, [selectedTask.id]));
          dispatch({
            type: UNASSIGN_TO_TASK,
            payload: employee.id,
          });
        }
        fillManpower();
      }
    });
  };

  return (
    <div>
      {modalPICOpen && (
        <StyledDialog
          isOpen={modalPICOpen}
          setIsOpen={() => setModalPICOpen(!modalPICOpen)}
          onClose={() => setModalPICOpen(false)}
          screenWidth={500}
        >
          <h1 className="font-bold mb-4">Add a PIC</h1>
          <div className="flex mb-4">
            <div className="w-full">
              <Select
                options={dropdownEmployees(engineers)}
                value={selectedPIC}
                onChange={setSelectedPIC}
                className="text-xs"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              text="Add"
              success="true"
              onClick={() => {
                savePIC();
              }}
            />
          </div>
        </StyledDialog>
      )}
      {modalEngineersOpen && (
        <StyledDialog
          isOpen={modalEngineersOpen}
          setIsOpen={() => setModalEngineersOpen(!modalEngineersOpen)}
          onClose={() => setModalEngineersOpen(false)}
          screenWidth={500}
        >
          <h1 className="font-bold mb-4">Add an Engineer/Coordinator</h1>
          <div className="flex mb-4">
            <div className="w-full">
              <Select
                options={dropdownEmployees(engineers)}
                value={selectedEngineer}
                onChange={setSelectedEngineer}
                className="text-xs"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              text="Add"
              success="true"
              onClick={() => {
                saveEngineer();
              }}
            />
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                setModalEngineersOpen(false);
                setSelectedEngineer({
                  value: 0,
                  label: "Select an Engineer/Coordinator...",
                });
              }}
            />
          </div>
        </StyledDialog>
      )}
      {modalCoordinator && (
        <StyledDialog
          isOpen={modalCoordinator}
          setIsOpen={() => setModalCoordinatorOpen(!modalCoordinator)}
          onClose={() => setModalCoordinatorOpen(false)}
          screenWidth={500}
        >
          <h1 className="font-bold mb-4">Add a Coordinator</h1>
          <div className="flex mb-4">
            <div className="w-full">
              <Select
                options={dropdownEmployees(coordinators)}
                value={selectedCoordinator}
                onChange={setSelectedCoordinator}
                className="text-xs"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              text="Add"
              success="true"
              onClick={() => {
                saveCoordinator();
              }}
            />
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                setModalCoordinatorOpen(false);
                setSelectedCoordinator({
                  value: 0,
                  label: "Select a Coordinator...",
                });
              }}
            />
          </div>
        </StyledDialog>
      )}
      {modalSupervisorsOpen && (
        <StyledDialog
          isOpen={modalSupervisorsOpen}
          setIsOpen={() => setModalSupervisorsOpen(!modalSupervisorsOpen)}
          onClose={() => setModalSupervisorsOpen(false)}
          screenWidth={500}
        >
          <h1 className="font-bold mb-4">Add a Supervisor</h1>
          <div className="flex mb-4">
            <div className="w-full">
              <Select
                options={dropdownEmployees(supervisors)}
                value={selectedSupervisor}
                onChange={setSelectedSupervisor}
                className="text-xs"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              text="Add"
              success="true"
              onClick={() => {
                saveSupervisor();
              }}
            />
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                setModalSupervisorsOpen(false);
                setSelectedSupervisor({
                  value: 0,
                  label: "Select an Supervisor...",
                });
              }}
            />
          </div>
        </StyledDialog>
      )}

      {modalWorkersOpen && (
        <StyledDialog
          isOpen={modalWorkersOpen}
          setIsOpen={() => setModalWorkersOpen(!modalWorkersOpen)}
          onClose={() => setModalWorkersOpen(false)}
          screenWidth={500}
        >
          <h1 className="font-bold mb-4">Add a Worker</h1>
          <div className="flex mb-4">
            <div className="w-full">
              <Select
                options={dropdownEmployees(workers)}
                value={selectedWorker}
                onChange={setSelectedWorker}
                className="text-xs"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              text="Add"
              success="true"
              onClick={() => {
                saveWorker();
              }}
            />
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                setModalWorkersOpen(false);
                setSelectedWorker({
                  value: 0,
                  label: "Select a Worker...",
                });
              }}
            />
          </div>
        </StyledDialog>
      )}

      {modalSafetyOpen && (
        <StyledDialog
          isOpen={modalSafetyOpen}
          setIsOpen={() => setModalSafetyOpen(!modalSafetyOpen)}
          onClose={() => setModalSafetyOpen(false)}
          screenWidth={500}
        >
          <h1 className="font-bold mb-4">Add Safety Personnel</h1>
          <div className="flex mb-4">
            <div className="w-full">
              <Select
                options={dropdownEmployees(safeties)}
                value={selectedSafety}
                onChange={setSelectedSafety}
                className="text-xs"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
              />
            </div>
          </div>
          <div className="flex">
            <Button
              text="Add"
              success="true"
              onClick={() => {
                saveSafety();
              }}
            />
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                setModalSafetyOpen(false);
                setSelectedSafety({
                  value: 0,
                  label: "Select Safety Personnel...",
                });
              }}
            />
          </div>
        </StyledDialog>
      )}
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Assigned Manpower
      </h1>
      <table className="table-fixed" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Position</th>
            <th className="text-xs px-4 py-2">Employee</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Project Manager</td>
            <td className="border px-2 py-2">
              {manpower.pm && (
                <div key={manpower.pm.id}>
                  <Avatar
                    name={`${manpower.pm.first_name} ${manpower.pm.last_name}`}
                    image={manpower.pm.avatar ? manpower.pm.avatar.image : null}
                  />
                </div>
              )}
            </td>
          </tr>
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Director</td>
            <td className="border px-2 py-2">
              {manpower.director &&
                manpower.director.map((dir) => (
                  <div key={dir.id} className="flex px-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(dir);
                        }}
                      />
                    )}
                    <Avatar
                      name={`${dir.first_name} ${dir.last_name}`}
                      image={dir.avatar ? dir.avatar.image : null}
                    />
                  </div>
                ))}
            </td>
          </tr> */}
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Person-in-charge (PIC)</td>
            <td className="border px-2 py-2">
              {manpower.pics &&
                manpower.pics.map((pic) => (
                  <div key={pic.id} className="flex px-2 py-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(pic);
                        }}
                      />
                    )}

                    <Avatar
                      name={`${pic.first_name} ${pic.last_name}`}
                      image={pic.avatar ? pic.avatar.image : null}
                    />
                  </div>
                ))}
              {user.employee.role_id !== roles.drftr && (
                <Button
                  primary="true"
                  text="+ add PICs"
                  onClick={() => {
                    setModalPICOpen(true);
                  }}
                />
              )}
            </td>
          </tr>
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Engineers/Coordinators</td>
            <td className="border px-2 py-2">
              {manpower.engineers &&
                manpower.engineers.map((engineer) => (
                  <div key={engineer.id} className="flex px-2 py-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(engineer);
                        }}
                      />
                    )}

                    <Avatar
                      name={`${engineer.first_name} ${engineer.last_name}`}
                      image={engineer.avatar ? engineer.avatar.image : null}
                    />
                  </div>
                ))}
              {user.employee.role_id !== roles.drftr && (
                <Button
                  primary="true"
                  text="+ add engineer/coordinator"
                  onClick={() => {
                    setModalEngineersOpen(true);
                  }}
                />
              )}
            </td>
          </tr>
          {/* <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Coordinators</td>
            <td className="border px-2 py-2">
              {manpower.coordinators &&
                manpower.coordinators.map((coordinator) => (
                  <div key={coordinator.id} className="flex px-2 py-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(coordinator);
                        }}
                      />
                    )}
                    <Avatar
                      name={`${coordinator.first_name} ${coordinator.last_name}`}
                      image={
                        coordinator.avatar ? coordinator.avatar.image : null
                      }
                    />
                  </div>
                ))}
              {user.employee.role_id !== roles.drftr && (
                <Button
                  primary="true"
                  text="+ add coordinator"
                  onClick={() => {
                    setModalCoordinatorOpen(true);
                  }}
                />
              )}
            </td>
          </tr> */}
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Supervisors</td>
            <td className="border px-2 py-2">
              {manpower.supervisors &&
                manpower.supervisors.map((supervisor) => (
                  <div key={supervisor.id} className="flex px-2 py-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(supervisor);
                        }}
                      />
                    )}
                    <Avatar
                      name={`${supervisor.first_name} ${supervisor.last_name}`}
                      image={supervisor.avatar ? supervisor.avatar.image : null}
                    />
                  </div>
                ))}
              {user.employee.role_id !== roles.drftr && (
                <Button
                  primary="true"
                  text="+ add supervisor"
                  onClick={() => {
                    setModalSupervisorsOpen(true);
                  }}
                />
              )}
            </td>
          </tr>
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Safety</td>
            <td className="border px-2 py-2">
              {manpower.safety &&
                manpower.safety.map((sfty) => (
                  <div key={sfty.id} className="flex px-2 py-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(sfty);
                        }}
                      />
                    )}
                    <Avatar
                      name={`${sfty.first_name} ${sfty.last_name}`}
                      image={sfty.avatar ? sfty.avatar.image : null}
                    />
                  </div>
                ))}
              {user.employee.role_id !== roles.drftr && (
                <Button
                  primary="true"
                  text="+ add safety"
                  onClick={() => {
                    setModalSafetyOpen(true);
                  }}
                />
              )}
            </td>
          </tr>
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Workers</td>
            <td className="border px-2 py-2">
              {manpower.workers &&
                manpower.workers.map((worker) => (
                  <div key={worker.id} className="flex px-2 py-2">
                    {user.employee.role_id !== roles.drftr && (
                      <Button
                        danger="true"
                        text="-"
                        onClick={() => {
                          removeEmployee(worker);
                        }}
                      />
                    )}
                    <Avatar
                      name={`${worker.first_name} ${worker.last_name}`}
                      image={worker.avatar ? worker.avatar.image : null}
                    />
                  </div>
                ))}
              {user.employee.role_id !== roles.drftr && (
                <Button
                  primary="true"
                  text="+ add worker"
                  onClick={() => {
                    setModalWorkersOpen(true);
                  }}
                />
              )}
            </td>
          </tr>
          {/* <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Quantity Surveyor</td>
            <td className="border px-2 py-2">
              {selectedTask.created_by && (
                <div className="flex px-2">
                  <Avatar
                    name={`${
                      selectedTask.created_by
                        ? selectedTask.created_by.employee.first_name
                        : ""
                    } ${
                      selectedTask.created_by
                        ? selectedTask.created_by.employee.last_name
                        : ""
                    }`}
                    image={
                      selectedTask.created_by.avatar
                        ? selectedTask.created_by.employee.avatar.image
                        : null
                    }
                  />
                </div>
              )}
            </td>
          </tr> */}
          <tr className={classNames("hover:bg-gray-200")}>
            <td className="border px-2 py-2 text-xs">Inactive Employees</td>
            <td className="border px-2 py-2">
              {manpower.inactiveEmployees &&
                manpower.inactiveEmployees.map((inactive) => (
                  <div key={inactive.id} className="flex px-2">
                    <Avatar
                      name={`${inactive.first_name} ${inactive.last_name}`}
                      image={inactive.avatar ? inactive.avatar.image : null}
                    />
                  </div>
                ))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
